import { InjectionToken } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { CoreState, localStorageSync, reducers } from '@eui/core';

import { environment } from '../../../environments/environment';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('Reducers', {
  factory: () => reducers,
});

export const metaReducers: MetaReducer<State>[] =
    !environment.production ? [localStorageSync, storeFreeze] : [localStorageSync];

export type State = CoreState;
