import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasInputChanged, hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../../component/base.component';

@Component({
    selector: 'rtpd-driver-contract',
    templateUrl: './contract.component.html',
})
export class DriverContractComponent extends BaseComponent implements OnChanges {

    public worldCountries: EuiAutoCompleteItem[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (hasInputChanged(changes.isReadOnly)) {
            this.refDataLoaded = true;
        }
        if (hasRefDataChanged(changes.refData)) {
            this.worldCountries = this.refData.worldCountries;
            reselectFormControlValue(this.driverApplicableLaw, this.worldCountries);
            this.refDataLoaded = true;
        }
    }

    public get driverContractStartDate(): FormControl {
        return this.form.get('driverContractStartDate') as FormControl;
    }

    public get driverApplicableLaw(): FormControl {
        return this.form.get('driverApplicableLaw') as FormControl;
    }
}
