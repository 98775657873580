<fieldset [disabled]="files == null" class="disable-border">
    <ng-container>
        <rtpd-file *ngFor="let file of files; let i = index; let odd = odd"
                   [styleClass]="odd ? 'my-2' : ''"
                   [file]="file" (remove)="removeFiles(i)">
        </rtpd-file>
    </ng-container>
</fieldset>

<ng-container *ngIf="files == null">
    <input #input type="file" class="hidden-file-input" [accept]="accept"
        [multiple]="maxFiles > 1" (change)="select($event)">
    <button euiButton euiSizeS euiPrimary euiOutline euiPrimary (click)="selectFiles(input)">
        <span euiIcon iconClass="eui-icon eui-icon-upload"></span>
        <span euiLabel>{{ browseFilesLabel | translate }}</span>
    </button>
</ng-container>
