import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, EuiAppConfig, GlobalConfig, I18nService } from '@eui/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStarterService {
    public configGlobal: GlobalConfig;

    public constructor(
        protected i18nService: I18nService,
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
    ) {
      this.configGlobal = this.config.global;
    }

    public start(): Observable<any> {
      return this.i18nService.init({ activeLang: this.configGlobal. i18n.i18nService.defaultLanguage });
    }

}
