<form [formGroup]="form">
    <ng-container *ngIf="isTermSearchVisible">
        <div *ngIf="enabled" class="row">
            <div class="ux-input-group col-md-5 margin-bottom-s">
                <input class="mx-1" euiInputText formControlName="term" placeholder="{{ placeholderLabel }}" (keydown.enter)="find()" (keyup)="onTermChange()"/>
                <span class="input-group-append">
                    <button euiButton euiPrimary (click)="find()">
                        <span euiIcon iconClass="eui-icon eui-icon-search"></span>
                        <span euiLabel>{{ buttonLabel }}</span>
                    </button>
                </span>
            </div>
        </div>
    </ng-container>

    <div *ngIf="hasAdvancedFilters">
        <div class="mt-2" *ngIf="!isAdvancedFiltersOpen">
          <a class="btn-link ux-u-text-underline me-1 eui-u-cursor-pointer" role="button" (click)="searchToggle.emit()">{{ 'term.search.show.advanced.filters' | translate }}</a>
            <a *ngIf="isAdvancedFiltersApplied"class="btn-link ux-u-text-underline ms-2 me-1 eui-u-cursor-pointer" role="button" (click)="reset()">{{ 'term.search.clear.advanced.filters' | translate }}</a>
            <em *ngIf="isAdvancedFiltersApplied"class="fa fa-filter ux-u-color-warning-light ms-2 me-1"></em>
            <span *ngIf="isAdvancedFiltersApplied">{{ 'term.search.advanced.filters.applied' | translate }}</span>
        </div>
        <div *ngIf="isAdvancedFiltersOpen">
            <div class="vertical-space"></div>
                <ng-container
                    [ngTemplateOutlet]="advancedFiltersRef"
                    [ngTemplateOutletContext]="{ $implicit: form.controls.advancedFilters, filters: filters, refData: refData }">
                </ng-container>
            <a class="btn-link ux-u-text-underline me-1 eui-u-cursor-pointer" role="button" (click)="searchToggle.emit()">{{ 'term.search.hide.advanced.filters' | translate }}</a>
            <a *ngIf="isAdvancedFiltersApplied"class="btn-link ux-u-text-underline ms-2 me-1 eui-u-cursor-pointer" role="button" (click)="reset()">{{ 'term.search.clear.advanced.filters' | translate }}</a>
        </div>
    </div>
</form>
