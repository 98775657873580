import { Status } from './status';

export interface Search {
    isAdvancedFiltersOpen?: boolean;
    isAdvancedFiltersApplied?: boolean;
    filters?: SearchFilters;
}

export interface SearchFilters {
    term?: string;
    limit?: string;
    startKey?: string;
    postingCountry?: string[];
    status?: Status[];
    driverId?: string[];
    endDateFrom?: string;
    endDateTo?: string;
}

export function isAdvancedFiltersApplied(filters: SearchFilters): boolean {
    return !!(filters &&
        (filters.endDateFrom || filters.endDateTo ||
        (filters.driverId && filters.driverId.length > 0) ||
        (filters.postingCountry && filters.postingCountry.length > 0) ||
        (filters.status && filters.status.length > 0)));
}

export interface SearchScan {
    declarationId: string;
    declarationSecurityKey: string;
}
