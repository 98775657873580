import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AWSAuthService } from '../services/aws-auth.service';

@Injectable()
export class AWSAuthInterceptor implements HttpInterceptor {

  public constructor(private authService: AWSAuthService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('http') || !this.authService.isEnabled()) {
      return this.handleRequest(request, next);
    } else if (this.authService.isAuthenticated()) {
      return this.handleRequest(this.setToken(request, this.authService.getJwtToken()), next);
    } else {
      return this.authService.signIn().pipe(
        switchMap(() => this.handleRequest(this.setToken(request, this.authService.getJwtToken()), next)),
      );
    }
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.signIn().pipe(
            switchMap(() => next.handle(this.setToken(request, this.authService.getJwtToken()))),
          );
        } else {
            return throwError(() => error);
        }
      }),
    );
  }

  private setToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      withCredentials: true,
      setHeaders: { 'Authorization': `Bearer ${token}` },
    });
  }
}
