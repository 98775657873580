import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '../utilities';

/**
 * This pipe checks if the first argument is greater than second argument.
 * Arguments can be numbers, strings or Date objects.
 * In case of dates, before performing the check, the arguments are formatted as 10 character string (the time will be wiped out).
 * This means Date.now() is not greater than new Date().
 */
@Injectable({ providedIn: 'root' })
@Pipe({ name: 'greaterThan' })
export class GreaterThanPipe implements PipeTransform {

  public transform(first: number | string | Date, second: number | string | Date, isDate = true): boolean {
    let ret = false;
    if (first && second) {
      if (isDate) {
        const firstFormatted = formatDate(first);
        const secondFormatted = formatDate(second);
        ret = firstFormatted > secondFormatted;
      } else {
        ret = first > second;
      }
    }
    return ret;
  }
}
