import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../component/base.component';

@Component({
    selector: 'rtpd-transport-manager-information',
    templateUrl: './transport-manager-information.component.html',
})
export class TransportManagerInformationComponent extends BaseComponent implements OnChanges {
    @Input() public isIdReadOnly = false;
    public postingCountries: EuiAutoCompleteItem[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (hasRefDataChanged(changes.refData)) {
            this.postingCountries = this.refData.postingCountries;
            reselectFormControlValue(this.transportManagerLicenseCountry, this.postingCountries);
            this.refDataLoaded = true;
        }
    }

    public get transportManagerEmail(): FormControl {
        return this.form.get('transportManagerEmail') as FormControl;
    }

    public get transportManagerFirstName(): FormControl {
        return this.form.get('transportManagerFirstName') as FormControl;
    }

    public get transportManagerLastName(): FormControl {
        return this.form.get('transportManagerLastName') as FormControl;
    }

    public get transportManagerPhone(): FormControl {
        return this.form.get('transportManagerPhone') as FormControl;
    }

    public get transportManagerLicenseNumber(): FormControl {
        return this.form.get('transportManagerLicenseNumber') as FormControl;
    }

    public get transportManagerLicenseCountry(): FormControl {
        return this.form.get('transportManagerLicenseCountry') as FormControl;
    }
}
