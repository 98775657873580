import { EuiEnvConfig } from '@eui/core';

export const environment: EuiEnvConfig = {
  production: true,
  enableDevToolRedux: false,
  name: 'dev',
  appVersion: require('../../../cdk/cdk.context.json').version + '-dev',
  appReleaseDate: '',
  modules: {},
  envDynamicConfig: {
    uri: 'assets/config/config.dev.json',
  },
};
