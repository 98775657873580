import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AWSAuthService } from '../services/aws-auth.service';
import { UserService } from '../services/user.service';
import { GrowlHelper } from '../services/growl-helper.service';

@Injectable({ providedIn: 'root' })
export class PermissionAuthGuard implements CanActivate {

    public constructor(
        private router: Router,
        private growlHelper: GrowlHelper,
        private authService: AWSAuthService,
        private userService: UserService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        if (!this.authService.isEnabled() || (this.userService.isAuthorized() &&
            this.userService.hasPermissions(route.data.permissions))) {
            return of(true);
        } else {
            this.growlHelper.growlError('Access is denied. User has no role associations required or attributes set.');
            console.error('Access is denied. User has no role associations required or attributes set.');
            this.router.navigate(['403'], { state: { accessDenied: true } });
            return of(false);
        }
    }
}
