import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty } from '../../core/helpers/null-value.util';
import { formatDate } from '../utilities';
import validator from 'validator';

export function patternValidator(name: string, pattern: RegExp): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value)) {
            return null;
        }
        return pattern.test(control.value) ? null : { [name]: true };
    };
}

export function emptyString(name: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value)) {
            return null;
        }
        return control.value.trim().length != 0 ? null : { [name]: true };
    };
}

export function requiredValueIdValidator(name: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value?.id)) {
            return { [name]: true };
        } else {
            return null;
        }
    };
}

export function futureDateValidator(name: string, includePresent = false): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (control.value) {
            if (includePresent) {
                return formatDate(new Date()) > formatDate(control.value) ? { [name]: true } : null;
            }
            return formatDate(new Date()) >= formatDate(control.value) ? { [name]: true } : null;
        }
        return null;
    };
}

export function dateOrFutureDateValidator(name: string, date: Date, includePresent = false): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (control.value) {
            if (includePresent) {
                const error = formatDate(date) > formatDate(control.value) ? { [name]: true } : null;
                if (error) {
                    // explicitly set the error, because, if you just return the error, Angular ignores it
                    control.setErrors({ [name]: true });
                }
                return error;
            }
            return formatDate(date) >= formatDate(control.value) ? { [name]: true } : null;
        }
        return null;
    };
}

export function emailValidator(name: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value)) {
            return null;
        }
        return validator.isEmail(control.value) ? null : { [name]: true };
    };
}

export function countryValidator(name: any): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value?.id) && !isEmpty(control.value?.label)) {
            return { [name]: true };
        } else {
            return null;
        }
    };
}

export function phoneValidator(name: string): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
        if (isEmpty(control.value)) {
            return null;
        }
        // valid phone number a + sign followed by 5 to 15 digits
        return /^\+\d{5,15}$/.test(control.value) ? null : { [name]: true };
    };
}

export class RTPDValidators {

    public static text(control: FormControl): ValidationErrors | null {
        return patternValidator('text', /^(?!^\s+$)(?!.+(\n)\1).+$/gs)(control);
    }

    public static phone(control: FormControl): ValidationErrors | null {
        return phoneValidator('phone')(control);
    }

    public static email(control: FormControl): ValidationErrors | null {
        return emailValidator('email')(control);
    }

    public static url(control: FormControl): ValidationErrors | null {
        return patternValidator('url', /^(https?):\/\/(-\.)?([^\s/?#]+\.?)+(\/[^\s]*)?$/gi)(control);
    }

    public static latinChars(control: FormControl): ValidationErrors | null {
        return patternValidator('latinChars', /^([A-Za-z\-'.\s]*)$/)(control);
    }

    public static presentOrFutureDate(control: FormControl): ValidationErrors | null {
        return futureDateValidator('presentOrFutureDate', true)(control);
    }

    public static dateOrFutureDateValidatorFn(date: Date): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            return dateOrFutureDateValidator('dateOrFutureDate', date, true)(control);
        };
    }

    public static dateOrFutureDate(control: FormControl, date: Date): ValidationErrors | null {
        return dateOrFutureDateValidator('dateOrFutureDate', date, true)(control);
    }

    public static required(control: FormControl): ValidationErrors | null {
        return emptyString('required')(control);
    }

    public static requiredValidCountry(control: FormControl): ValidationErrors | null {
        return requiredValueIdValidator('requiredValidCountry')(control);
    }

    public static requiredValidDocumentType(control: FormControl): ValidationErrors | null {
        return requiredValueIdValidator('requiredValidDocumentType')(control);
    }

    public static validCountry(control: FormControl): ValidationErrors | null {
        return countryValidator('validCountry')(control);
    }
}
