import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { isBannerVisible, isProduction, isTraining } from '@rtpd/shared';
import { EUI_CONFIG_TOKEN, I18nService } from '@eui/core';
import { AWSAuthService } from '@rtpd/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rtpd-404',
    templateUrl: './page-404.components.html',
    styleUrls: ['./page-404.component.scss'],
})
export class Page404Component implements OnInit, OnDestroy {
    public appVersion: string;
    public appReleaseDate: string;
    public privacyStatementLink: any = { url: 'assets/privacy/privacy statement en.pdf', label: 'screens.footer.privacy.statement' };
    public authenticated: boolean;
    public envName: string;
    public nonProduction: boolean;
    public isTraining: boolean;
    public showBanner: boolean;
    private _onDestroy$ = new Subject<void>();

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
        private authService: AWSAuthService,
        private translateService: TranslateService,
        private i18nService: I18nService,
    ) {
    }

    public ngOnInit() {
        this.nonProduction = !isProduction(this.euConfig.environment.name);
        this.isTraining = isTraining(this.euConfig.environment.name);
        this.showBanner = isBannerVisible('screens.landing.top.message.production.notification',
            this.translateService,
            this.euConfig);
        this.envName = this.euConfig.environment.name;
        this.appVersion = this.euConfig.environment.appVersion;
        this.appReleaseDate = this.euConfig.environment.appReleaseDate;
        this.i18nService.getState().subscribe(language => {
            this.privacyStatementLink.url = `assets/privacy/privacy statement ${language.activeLang}.pdf`;
        });
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }
}

