import { Action } from '@ngrx/store';
import { Declaration, SearchFilters } from '../models';
import { Error, HistoryLog } from '@rtpd/shared';
import { DriverEmail } from '../../../shared/models/attachment.model';
import { Driver } from '../../drivers/models';
import { BulkResponse } from '../models/bulk-response.model';
import { BulkEditDeclarationRequest } from '../models/bulk-edit-declaration.request';

export const INIT_ACTION = '[RTPD Declarations] Init';

export const CHANGE_TERM_ACTION = '[RTPD Declarations] Change Term';
export const RESET_TERM_ACTION = '[RTPD Declarations] Reset Term';

export const SEARCH_DECLARATIONS = '[RTPD Declarations] Search';
export const SEARCH_DECLARATIONS_SUCCESS = '[RTPD Declarations] Search Success';
export const SEARCH_DECLARATIONS_FAIL = '[RTPD Declarations] Search Fail';
export const SEARCH_DECLARATIONS_INITIAL_FILTERS = '[RTPD Declarations] Search Initial Filters';
export const SEARCH_DECLARATIONS_TOGGLE = '[RTPD Declarations] Search Toggle';

export const LOAD_NEXT_DECLARATIONS = '[RTPD Declarations] Load Next';
export const LOAD_NEXT_DECLARATIONS_SUCCESS = '[RTPD Declarations] Load Next Success';
export const LOAD_NEXT_DECLARATIONS_FAIL = '[RTPD Declarations] Load Next Fail';

export const REFRESH_DECLARATIONS = '[RTPD Declarations] Refresh';
export const REFRESH_DECLARATIONS_SUCCESS = '[RTPD Declarations] Refresh Success';
export const REFRESH_DECLARATIONS_FAIL = '[RTPD Declarations] Refresh Fail';

export const CREATE_DECLARATIONS = '[RTPD Declarations] Create';
export const CREATE_DECLARATIONS_SUCCESS = '[RTPD Declarations] Create Success';
export const CREATE_DECLARATIONS_FAIL = '[RTPD Declarations] Create Fail';

export const GET_DECLARATIONS = '[RTPD Declarations] Get';
export const GET_DECLARATIONS_SUCCESS = '[RTPD Declarations] Get Success';
export const GET_DECLARATIONS_FAIL = '[RTPD Declarations] Get Fail';

export const GET_FOR_CREATE_DECLARATIONS = '[RTPD Declarations] Get For Create';
export const GET_FOR_CREATE_DECLARATIONS_SUCCESS = '[RTPD Declarations] Get For Create Success';
export const GET_FOR_CREATE_DECLARATIONS_FAIL = '[RTPD Declarations] Get For Create Fail';

export const UPDATE_DECLARATIONS = '[RTPD Declarations] Update';
export const UPDATE_DECLARATIONS_SUCCESS = '[RTPD Declarations] Update Success';
export const UPDATE_DECLARATIONS_FAIL = '[RTPD Declarations] Update Fail';

export const SUBMIT_DECLARATIONS = '[RTPD Declarations] Submit';
export const SUBMIT_DECLARATIONS_SUCCESS = '[RTPD Declarations] Submit Success';
export const SUBMIT_DECLARATIONS_FAIL = '[RTPD Declarations] Submit Fail';

export const UPDATE_SUBMITTED_DECLARATIONS = '[RTPD Declarations] Update Submitted';
export const UPDATE_SUBMITTED_DECLARATIONS_SUCCESS = '[RTPD Declarations] Update Submitted Success';
export const UPDATE_SUBMITTED_DECLARATIONS_FAIL = '[RTPD Declarations] Update Submitted Fail';

export const DELETE_DECLARATIONS = '[RTPD Declarations] Delete';
export const DELETE_DECLARATIONS_SUCCESS = '[RTPD Declarations] Delete Success';
export const DELETE_DECLARATIONS_FAIL = '[RTPD Declarations] Delete Fail';

export const ADD_DECLARATIONS = '[RTPD Declarations] Add';
export const EDIT_DECLARATIONS = '[RTPD Declarations] Edit';
export const COPY_DECLARATIONS = '[RTPD Declarations] Copy';
export const VIEW_DECLARATIONS = '[RTPD Declarations] View';

export const PRINT_DECLARATION = '[RTPD Declarations] Print';
export const PRINT_DECLARATION_SUCCESS = '[RTPD Declarations] Print success';
export const PRINT_DECLARATION_FAIL = '[RTPD Declarations] Print fail';
export const PRINT_DECLARATION_DOWNLOAD_SUCCESS = '[RTPD Declarations] Print file download success';

export const CHANGE_DECLARATIONS = '[RTPD Declarations] Change';
export const CANCEL_DECLARATIONS = '[RTPD Declarations] Cancel';
export const CHANGE_DRIVER = '[RTPD Declarations] Change Driver';

export const WITHDRAW_DECLARATIONS = '[RTPD Declarations] Withdraw';
export const WITHDRAW_DECLARATIONS_SUCCESS = '[RTPD Declarations] Withdraw Success';
export const WITHDRAW_DECLARATIONS_FAIL = '[RTPD Declarations] Withdraw Fail';
export const GET_HISTORY_LOG_DECLARATIONS = '[RTPD Declarations] Get History Log';
export const GET_HISTORY_LOG_DECLARATIONS_SUCCESS = '[RTPD Declarations] Get History Log success';
export const GET_HISTORY_LOG_DECLARATIONS_FAIL = '[RTPD Declarations] Get History Log fail';

export const SEND_DECLARATION_BY_EMAIL = '[RTPD Declarations] Send by email';
export const CANCEL_SEND_DECLARATION_BY_EMAIL = '[RTPD Declarations] Cancel send by email';
export const CHANGE_DRIVER_EMAIL = '[RTPD Declarations] Change driver email';
export const SEND_DRIVER_EMAIL_BY_EMAIL = '[RTPD Declarations] Send driver email';
export const SEND_DRIVER_EMAIL_BY_EMAIL_SUCCESS = '[RTPD Declarations] Send driver email success';
export const SEND_DRIVER_EMAIL_BY_EMAIL_FAIL = '[RTPD Declarations] Send driver email fail';

export const REF_DATA_LOADED = '[RTPD Declarations] ref data loaded';
export const DRIVER_PATCHED = '[RTPD Declarations] driver patched';
export const FORM_DATA_HANDLED = '[RTPD Declarations] form data handled';
export const FORM_DATA_PATCHED = '[RTPD Declarations] form data patched';

export const SEARCH_DRIVERS = '[RTPD Declarations] Search Drivers';
export const SEARCH_DRIVERS_SUCCESS = '[RTPD Declarations] Search Drivers Success';
export const SEARCH_DRIVERS_FAIL = '[RTPD Declarations] Search Drivers Fail';

export const SET_FIELD_SORT_ORDER = '[RTPD Declarations] Set Field Order';
export const CLEAR_SORT_ORDER = '[RTPD Declarations] Clear Sort Order';

export const CHANGE_LANGUAGE = '[RTPD Declarations] Language changed';

export const SELECT_DECLARATIONS = '[RTPD Declarations] Select Declarations';
export const CLOSE_BULK_SUBMIT = '[RTPD Declarations] Close Bulk Submit Declarations';
export const CANCEL_BULK_SUBMIT = '[RTPD Declarations] Cancel Bulk Submit Declarations';
export const BULK_SUBMIT = '[RTPD Declarations] Bulk Submit Declarations';
export const BULK_SUBMIT_SUCCESS = '[RTPD Declarations] Bulk Submit Declarations Success';
export const BULK_SUBMIT_FAIL = '[RTPD Declarations] Bulk Submit Declarations Fail';
export const VALIDATE_BULK_SUBMIT = '[RTPD Declarations] Validate Declarations Before Bulk Submission';
export const VALIDATE_BULK_SUBMIT_SUCCESS = '[RTPD Declarations] Validate Declarations Before Bulk Submission Success';
export const VALIDATE_BULK_SUBMIT_FAIL = '[RTPD Declarations] Validate Declarations Before Bulk Submission Fail';

export const CHANGE_BULK_EDIT_DECLARATIONS = '[RTPD Declarations] Bulk Edit Change';

export const CANCEL_BULK_EDIT = '[RTPD Declarations] Cancel Bulk Edit Declarations';
export const CLOSE_BULK_EDIT = '[RTPD Declarations] Close Bulk Edit Declarations';
export const BULK_EDIT = '[RTPD Declarations] Bulk Edit Declarations';
export const BULK_EDIT_SUCCESS = '[RTPD Declarations] Bulk Edit Declarations Success';
export const BULK_EDIT_FAIL = '[RTPD Declarations] Bulk Edit Declarations Fail';
export const VALIDATE_BULK_EDIT = '[RTPD Declarations] Validate Declarations Before Bulk Edit';
export const VALIDATE_BULK_EDIT_SUCCESS = '[RTPD Declarations] Validate Declarations Before Bulk Edit Success';
export const VALIDATE_BULK_EDIT_FAIL = '[RTPD Declarations] Validate Declarations Before Bulk Edit Fail';
export const DISCARD_BULK_EDIT_VALIDATION_RESULT = '[RTPD Declarations] Discard Bulk Edit Declarations Validation Result';

export const START_LOADING = '[RTPD Declarations] Start Loading';
export const END_LOADING = '[RTPD Declarations] End Loading';

export class InitAction implements Action {
    public readonly type: string = INIT_ACTION;
}

export class ChangeTermAction implements Action {
    public readonly type: string = CHANGE_TERM_ACTION;

    public constructor(public payload: string) {
    }
}

export class ResetTermAction implements Action {
    public readonly type: string = RESET_TERM_ACTION;
}

export class SearchDeclarationsAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS;

    public constructor(public payload: SearchFilters = undefined) {
    }
}

export class SearchDeclarationsSuccessAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_SUCCESS;

    public constructor(public count, public payload: Array<Declaration>, public lastEvaluatedKey: string) {
    }
}

export class SearchDeclarationsFailAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class LoadNextDeclarationsAction implements Action {
    public readonly type: string = LOAD_NEXT_DECLARATIONS;
}

export class LoadNextDeclarationsSuccessAction implements Action {
    public readonly type: string = LOAD_NEXT_DECLARATIONS_SUCCESS;

    public constructor(public count, public payload: Array<Declaration>, public lastEvaluatedKey: string) {
    }
}

export class LoadNextDeclarationsFailAction implements Action {
    public readonly type: string = LOAD_NEXT_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class RefreshDeclarationsAction implements Action {
    public readonly type: string = REFRESH_DECLARATIONS;
}

export class RefreshDeclarationsSuccessAction implements Action {
    public readonly type: string = REFRESH_DECLARATIONS_SUCCESS;

    public constructor(public count, public payload: Array<Declaration>, public lastEvaluatedKey: string) {
    }
}

export class RefreshDeclarationsFailAction implements Action {
    public readonly type: string = REFRESH_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class SearchDeclarationsInitialFiltersAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_INITIAL_FILTERS;

    public constructor(public payload: SearchFilters = undefined) {
    }
}

export class SearchDeclarationsToggleAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_TOGGLE;
}

export class CreateDeclarationsAction implements Action {
    public readonly type: string = CREATE_DECLARATIONS;
}

export class CreateDeclarationsSuccessAction implements Action {
    public readonly type: string = CREATE_DECLARATIONS_SUCCESS;
}

export class CreateDeclarationsFailAction implements Action {
    public readonly type: string = CREATE_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class GetDeclarationsAction implements Action {
    public readonly type: string = GET_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class GetDeclarationsSuccessAction implements Action {
    public readonly type: string = GET_DECLARATIONS_SUCCESS;

    public constructor(public payload: Declaration) {
    }
}

export class GetDeclarationsFailAction implements Action {
    public readonly type: string = GET_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class GetForCreateDeclarationsAction implements Action {
    public readonly type: string = GET_FOR_CREATE_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class GetForCreateDeclarationsSuccessAction implements Action {
    public readonly type: string = GET_FOR_CREATE_DECLARATIONS_SUCCESS;

    public constructor(public payload: Declaration) {
    }
}

export class GetForCreateDeclarationsFailAction implements Action {
    public readonly type: string = GET_FOR_CREATE_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class UpdateDeclarationsAction implements Action {
    public readonly type: string = UPDATE_DECLARATIONS;
}

export class UpdateDeclarationsSuccessAction implements Action {
    public readonly type: string = UPDATE_DECLARATIONS_SUCCESS;
}

export class UpdateDeclarationsFailAction implements Action {
    public readonly type: string = UPDATE_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class SubmitDeclarationsAction implements Action {
    public readonly type: string = SUBMIT_DECLARATIONS;
}

export class SubmitDeclarationsSuccessAction implements Action {
    public readonly type: string = SUBMIT_DECLARATIONS_SUCCESS;
}

export class SubmitDeclarationsFailAction implements Action {
    public readonly type: string = SUBMIT_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class UpdateSubmittedDeclarationsAction implements Action {
    public readonly type: string = UPDATE_SUBMITTED_DECLARATIONS;
}

export class UpdateSubmittedDeclarationsSuccessAction implements Action {
    public readonly type: string = UPDATE_SUBMITTED_DECLARATIONS_SUCCESS;
}

export class UpdateSubmittedDeclarationsFailAction implements Action {
    public readonly type: string = UPDATE_SUBMITTED_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class DeleteDeclarationsAction implements Action {
    public readonly type: string = DELETE_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class DeleteDeclarationsSuccessAction implements Action {
    public readonly type: string = DELETE_DECLARATIONS_SUCCESS;
}

export class DeleteDeclarationsFailAction implements Action {
    public readonly type: string = DELETE_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class AddDeclarationsAction implements Action {
    public readonly type: string = ADD_DECLARATIONS;
}

export class EditDeclarationsAction implements Action {
    public readonly type: string = EDIT_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class CopyDeclarationsAction implements Action {
    public readonly type: string = COPY_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class ViewDeclarationsAction implements Action {
    public readonly type: string = VIEW_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class PrintDeclarationAction implements Action {
    public readonly type: string = PRINT_DECLARATION;

    public constructor(public payload: string) {
    }
}

export class ChangeDeclarationsAction implements Action {
    public readonly type: string = CHANGE_DECLARATIONS;

    public constructor(public payload: Declaration) {
    }
}

export class CloseBulkSubmitDeclarationsAction implements Action {
    public readonly type: string = CLOSE_BULK_SUBMIT;
}

export class CancelBulkSubmitDeclarationsAction implements Action {
    public readonly type: string = CANCEL_BULK_SUBMIT;
}

export class CancelDeclarationsAction implements Action {
    public readonly type: string = CANCEL_DECLARATIONS;
}

export class ChangeDriverAction implements Action {
    public readonly type: string = CHANGE_DRIVER;
}

export class WithdrawDeclarationsAction implements Action {
    public readonly type: string = WITHDRAW_DECLARATIONS;
}

export class WithdrawDeclarationsSuccessAction implements Action {
    public readonly type: string = WITHDRAW_DECLARATIONS_SUCCESS;
}

export class WithdrawDeclarationsFailAction implements Action {
    public readonly type: string = WITHDRAW_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class GetHistoryLogDeclarationsAction implements Action {
    public readonly type: string = GET_HISTORY_LOG_DECLARATIONS;

    public constructor(public payload: string) {
    }
}

export class GetHistoryLogDeclarationsSuccessAction implements Action {
    public readonly type: string = GET_HISTORY_LOG_DECLARATIONS_SUCCESS;

    public constructor(public payload: HistoryLog[]) {
    }
}

export class GetHistoryLogDeclarationsFailAction implements Action {
    public readonly type: string = GET_HISTORY_LOG_DECLARATIONS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class SendDeclarationByEmailAction implements Action {
    public readonly type: string = SEND_DECLARATION_BY_EMAIL;

    public constructor(public payload: DriverEmail) {
    }
}

export class ChangeDriverEmailAction implements Action {
    public readonly type: string = CHANGE_DRIVER_EMAIL;

    public constructor(public payload: DriverEmail) {
    }
}

export class CancelSendDeclarationByEmailAction implements Action {
    public readonly type: string = CANCEL_SEND_DECLARATION_BY_EMAIL;
}

export class SendDriverEmailByEmailAction implements Action {
    public readonly type: string = SEND_DRIVER_EMAIL_BY_EMAIL;

    public constructor(public payload: string) {
    }
}

export class SendDriverEmailByEmailSuccessAction implements Action {
    public readonly type: string = SEND_DRIVER_EMAIL_BY_EMAIL_SUCCESS;
}

export class SendDriverEmailByEmailFailAction implements Action {
    public readonly type: string = SEND_DRIVER_EMAIL_BY_EMAIL_FAIL;

    public constructor(public payload: Error) {
    }
}

export class RefDataLoadedAction implements Action {
    public readonly type: string = REF_DATA_LOADED;
}

export class DriverPatchedAction implements Action {
    public readonly type: string = DRIVER_PATCHED;
}

export class FormDataHandledAction implements Action {
    public readonly type: string = FORM_DATA_HANDLED;
}

export class FormDataPatchedAction implements Action {
    public readonly type: string = FORM_DATA_PATCHED;
}

export class SearchDriversAction implements Action {
    public readonly type: string = SEARCH_DRIVERS;

    public constructor(public payload: SearchFilters = undefined) {
    }
}

export class SearchDriversSuccessAction implements Action {
    public readonly type: string = SEARCH_DRIVERS_SUCCESS;

    public constructor(public payload: Array<Driver>) {
    }
}

export class SearchDriversFailAction implements Action {
    public readonly type: string = SEARCH_DRIVERS_FAIL;

    public constructor(public payload: Error) {
    }
}

export class SetFieldSortOrderAction implements Action {
    public readonly type: string = SET_FIELD_SORT_ORDER;

    public constructor(public field: string, public sortOrder: number) {
    }
}

export class ClearSortOrderAction implements Action {
    public readonly type: string = CLEAR_SORT_ORDER;
}

export class PrintDeclarationSuccessAction implements Action {
    public readonly type: string = PRINT_DECLARATION_SUCCESS;

    public constructor(public payload: string) {
    }
}

export class PrintDeclarationFailAction implements Action {
    public readonly type: string = PRINT_DECLARATION_FAIL;

    public constructor(public payload: Error) {
    }
}

export class PrintDeclarationDownloadSuccessAction implements Action {
    public readonly type: string = PRINT_DECLARATION_DOWNLOAD_SUCCESS;
}

export class LanguageChangedAction implements Action {
    public readonly type: string = CHANGE_LANGUAGE;

    public constructor(public payload: string) {
    }
}

export class SelectDeclarationsAction implements Action {
    public readonly type: string = SELECT_DECLARATIONS;

    public constructor(public payload: any[]) {
    }
}

export class ValidateBulkSubmitAction implements Action {
    public readonly type: string = VALIDATE_BULK_SUBMIT;
}

export class ValidateBulkSubmitSuccessAction implements Action {
    public readonly type: string = VALIDATE_BULK_SUBMIT_SUCCESS;

    public constructor(public payload: BulkResponse) {
    }
}

export class ValidateBulkSubmitFailAction implements Action {
    public readonly type: string = VALIDATE_BULK_SUBMIT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class BulkSubmitAction implements Action {
    public readonly type: string = BULK_SUBMIT;
}

export class BulkSubmitSuccessAction implements Action {
    public readonly type: string = BULK_SUBMIT_SUCCESS;

    public constructor(public payload: BulkResponse) {
    }
}

export class BulkSubmitFailAction implements Action {
    public readonly type: string = BULK_SUBMIT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class CloseBulkEditDeclarationsAction implements Action {
    public readonly type: string = CLOSE_BULK_EDIT;
}

export class CancelBulkEditDeclarationsAction implements Action {
    public readonly type: string = CANCEL_BULK_EDIT;
}

export class ChangeBulkEditDeclarationsAction implements Action {
    public readonly type: string = CHANGE_BULK_EDIT_DECLARATIONS;

    public constructor(public payload: BulkEditDeclarationRequest) {
    }
}

export class ValidateBulkEditAction implements Action {
    public readonly type: string = VALIDATE_BULK_EDIT;
}

export class DiscardBulkEditValidationResultAction implements Action {
    public readonly type: string = DISCARD_BULK_EDIT_VALIDATION_RESULT;
}

export class ValidateBulkEditSuccessAction implements Action {
    public readonly type: string = VALIDATE_BULK_EDIT_SUCCESS;

    public constructor(public payload: BulkResponse) {
    }
}

export class ValidateBulkEditFailAction implements Action {
    public readonly type: string = VALIDATE_BULK_EDIT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class BulkEditAction implements Action {
    public readonly type: string = BULK_EDIT;
}

export class BulkEditSuccessAction implements Action {
    public readonly type: string = BULK_EDIT_SUCCESS;

    public constructor(public payload: BulkResponse) {
    }
}

export class BulkEditFailAction implements Action {
    public readonly type: string = BULK_EDIT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class StartLoadingAction implements Action {
    public readonly type: string = START_LOADING;
}

export class EndLoadingAction implements Action {
    public readonly type: string = END_LOADING;
}

export type Actions =
    InitAction |
    ChangeTermAction |
    ResetTermAction |
    SearchDeclarationsInitialFiltersAction |
    SearchDeclarationsAction |
    SearchDeclarationsSuccessAction |
    SearchDeclarationsFailAction |
    LoadNextDeclarationsAction |
    LoadNextDeclarationsSuccessAction |
    LoadNextDeclarationsFailAction |
    RefreshDeclarationsAction |
    RefreshDeclarationsSuccessAction |
    RefreshDeclarationsFailAction |
    SearchDeclarationsToggleAction |
    CreateDeclarationsAction |
    CreateDeclarationsSuccessAction |
    CreateDeclarationsFailAction |
    GetDeclarationsAction |
    GetDeclarationsSuccessAction |
    GetDeclarationsFailAction |
    GetForCreateDeclarationsAction |
    GetForCreateDeclarationsSuccessAction |
    GetForCreateDeclarationsFailAction |
    UpdateDeclarationsAction |
    UpdateDeclarationsSuccessAction |
    UpdateDeclarationsFailAction |
    SubmitDeclarationsAction |
    SubmitDeclarationsSuccessAction |
    SubmitDeclarationsFailAction |
    UpdateSubmittedDeclarationsAction |
    UpdateSubmittedDeclarationsSuccessAction |
    UpdateSubmittedDeclarationsFailAction |
    DeleteDeclarationsAction |
    DeleteDeclarationsSuccessAction |
    DeleteDeclarationsFailAction |
    AddDeclarationsAction |
    EditDeclarationsAction |
    CopyDeclarationsAction |
    ViewDeclarationsAction |
    ChangeDeclarationsAction |
    CancelDeclarationsAction |
    ChangeDriverAction |
    WithdrawDeclarationsAction |
    WithdrawDeclarationsSuccessAction |
    WithdrawDeclarationsFailAction |
    GetHistoryLogDeclarationsAction |
    GetHistoryLogDeclarationsSuccessAction |
    GetHistoryLogDeclarationsFailAction |
    SendDeclarationByEmailAction |
    ChangeDriverEmailAction |
    CancelSendDeclarationByEmailAction |
    SendDriverEmailByEmailAction |
    SendDriverEmailByEmailSuccessAction |
    SendDriverEmailByEmailFailAction |
    RefDataLoadedAction |
    DriverPatchedAction |
    FormDataHandledAction |
    FormDataPatchedAction |
    SearchDriversAction |
    SearchDriversSuccessAction |
    SearchDriversFailAction |
    SetFieldSortOrderAction |
    PrintDeclarationDownloadSuccessAction |
    PrintDeclarationFailAction |
    PrintDeclarationSuccessAction |
    PrintDeclarationAction |
    LanguageChangedAction |
    ClearSortOrderAction |
    SelectDeclarationsAction |
    BulkSubmitAction |
    BulkSubmitSuccessAction |
    BulkSubmitFailAction |
    ValidateBulkSubmitAction |
    ValidateBulkSubmitSuccessAction |
    ValidateBulkSubmitFailAction |
    CloseBulkSubmitDeclarationsAction |
    BulkEditAction |
    BulkEditSuccessAction |
    BulkEditFailAction |
    ChangeBulkEditDeclarationsAction |
    ValidateBulkEditAction |
    ValidateBulkEditSuccessAction |
    ValidateBulkEditFailAction |
    DiscardBulkEditValidationResultAction |
    CloseBulkEditDeclarationsAction |
    StartLoadingAction |
    EndLoadingAction
    ;
