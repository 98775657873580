<div class="notranslate">
    <eui-app>
        <eui-app-top-message *ngIf="isTraining" color="error" [isCloseable]="true">
            <h5 [innerHtml]= "'screens.landing.top.message.training.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-top-message *ngIf="showBanner" euiWarning [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.production.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-header id="landing-header">
            <eui-header>
                <eui-header-logo></eui-header-logo>
                <eui-header-environment *ngIf="nonProduction">{{ envName }}</eui-header-environment>
                <eui-header-app>
                    <eui-header-app-name class="appname">{{ 'application.title.full' | translate }}
                    </eui-header-app-name>
                </eui-header-app>
            </eui-header>
        </eui-app-header>

        <eui-app-toolbar id="landing-toolbar">
            <eui-toolbar>
                <eui-toolbar-logo></eui-toolbar-logo>
            </eui-toolbar>
        </eui-app-toolbar>

        <eui-app-page-wrapper id="landing-wrapper" class="no-flex">
            <eui-page-header label="{{ 'screens.landing.not.logged.title' | translate }}">
            </eui-page-header>
            <div class="eui-page-header" ></div>
            <eui-block-content>
                <p><strong>{{ 'screens.landing.not.logged.text.1' | translate }}</strong></p>
                <p>{{ 'screens.landing.not.logged.text.2' | translate }}</p>
                <ul>
                    <li>{{ 'screens.landing.not.logged.text.3' | translate }}</li>
                    <li>{{ 'screens.landing.not.logged.text.4' | translate }}</li>
                </ul>
                <p><strong>{{ 'screens.landing.not.logged.text.5' | translate }}</strong></p>
                <p>{{ 'screens.landing.not.logged.text.6' | translate }}</p>
                <p>{{ 'screens.landing.not.logged.text.7' | translate }}</p>
                <p>{{ 'screens.landing.not.logged.text.8' | translate }}</p>
                <p>{{ 'screens.landing.not.logged.text.9' | translate }}
                    <a target="_blank" rel="noopener" [routerLink]="['/help']">{{ "screens.help.title" | translate }}</a>
                </p>

            </eui-block-content>
            <div class="list-card">
                <eui-card>
                    <eui-card-content>
                        <div class="content-card">
                            <span>{{ 'screens.landing.not.logged.label.create.account' | translate }} </span>
                            <button euiButton euiBlockButton euiPrimary
                                    (click)="openDialog()">{{ 'screens.landing.not.logged.button.create.account'| translate }}</button>
                        </div>
                    </eui-card-content>
                </eui-card>
                &nbsp;
                <eui-card>
                    <eui-card-content>
                        <div class="content-card">
                            <span>{{ 'screens.landing.not.logged.label.Login' | translate }} </span>
                            <button euiButton euiOutline euiBlockButton euiPrimary [routerLink]="['/home']"
                                    routerLinkActive="router-link-active">
                                <span euiLabel>{{ 'screens.landing.not.logged.button.Login' |translate }} </span>
                            </button>&nbsp;
                        </div>
                    </eui-card-content>
                </eui-card>
            </div>
        </eui-app-page-wrapper>

        <eui-app-footer id="landing-footer" >
            <eui-footer>
                <div class="col-12 text-center">
                    <div class="flex-container justify-content-around">
                        <span>
                            <span *ngIf="appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong> - {{ appReleaseDate }}</span>
                            <span *ngIf="!appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong></span>
                            <span>
                                |
                                <a *ngIf="privacyStatementLink.url"
                                   target="_blank" rel="noopener"
                                   href="{{ privacyStatementLink.url }}">{{ privacyStatementLink.label | translate }}</a>
                            </span>
                        </span>
                    </div>
                </div>
            </eui-footer>
        </eui-app-footer>

    </eui-app>

    <ng-container id="landing-isIE11-footer" *ngIf="isIE11">
        <div>
            <strong>{{ 'screens.common.warning.label' | translate }} </strong> - {{
            'screens.common.obsolete.browser.message' | translate }}
        </div>
    </ng-container>
</div>
