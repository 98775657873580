import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'messageTypeKey' })
export class MessageTypeKeyPipe implements PipeTransform {
    public transform(messageType: string, messageTypes: Object, keyType: string): string {
        let key = '';

        if (messageTypes && messageType && keyType) {
            key = messageTypes[`${messageType}`][`${keyType}`];
        }

        return key;
    }
}
