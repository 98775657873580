import { UxDynamicModalConfig, UxDynamicModalService } from '@eui/components/legacy/ux-dynamic-modal';
import { UxButtonConfig } from '@eui/components/legacy/ux-button';
import { TranslateService } from '@ngx-translate/core';

export class RtpdDynamicMessageBox extends UxDynamicModalConfig {
    private _uxDynamicModalService: UxDynamicModalService;
    private _translateService: TranslateService;

    public constructor(
      uxModalConfig: any,
      uxDynamicModalService: UxDynamicModalService,
      translateService: TranslateService,
    ) {
      const yesButton = new UxButtonConfig({
        label: translateService.instant('screens.common.message.box.yes.label'),
        typeClass: 'primary',
        styleClass: 'ux-u-width-5 ml-1 button-margin',
        onClick: (portalHostRef, portalRef) => {
          if (uxModalConfig.onConfirmation) {
            uxModalConfig.onConfirmation();
          }
          uxDynamicModalService.closeModal(portalHostRef, portalRef);
        },
      });

      const noButton = new UxButtonConfig({
        label: translateService.instant('screens.common.message.box.no.label'),
        typeClass: 'secondary',
        styleClass: 'ux-u-width-5 ml-1 button-margin',
        onClick: (portalHostRef, portalRef) => {
          if (uxModalConfig.onCancel) {
              uxModalConfig.onCancel();
          }
          uxDynamicModalService.closeModal(portalHostRef, portalRef);
        },
      });

      super({
        isSizeSmall: true,
        isShowCloseButton: false,
        isCloseModalOnEscape: true,
        customFooterContent: {
          right: {
            buttons: [
              uxModalConfig.reverseButtons ? yesButton : noButton,
              uxModalConfig.reverseButtons ? noButton : yesButton,
            ],
          },
        },
        ...uxModalConfig,
      });

      this._uxDynamicModalService = uxDynamicModalService;
      this._translateService = translateService;
    }

    public openMessageBox() {
      this._uxDynamicModalService.openModal(this);
    }
}
