import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { ReleasesService, GrowlHelper } from '@rtpd/core';
import { Release } from '@rtpd/shared';

@Component({
    selector: 'rtpd-releases-component',
    templateUrl: './releases.component.html',
})
export class RtpdReleasesComponent implements OnInit, OnChanges {
    @Input() public showMore = true; // true by default to allow the page search for all releases
    @Output() public show = new EventEmitter<boolean>();

    private _defaultShowCount = 5;
    private _releases: Release[];

    public releases: Release[];

    public constructor(
        private releasesService: ReleasesService,
        private growlHelper: GrowlHelper,
    ) {}

    public ngOnInit(): void {
        this.releasesService.getReleases()
            .subscribe({
                next: releases => {
                    this._releases = releases.releases;
                    this.setReleasesToShow();
                    this.show.emit(releases.show);
                },
                error: error => {
                    this.growlHelper.growlError(`Releases fetch error: ${error.message}`);
                    this.show.emit(false);
                },
            });
    }

    public ngOnChanges() {
        this.setReleasesToShow();
    }

    public toggleShowMore() {
        this.showMore = !this.showMore;
        this.setReleasesToShow();
    }

    private setReleasesToShow() {
        if (this.showMore) {
            this.releases = this._releases;
        } else {
            this.releases = this._releases?.slice(0, this._defaultShowCount);
        }
    }
}
