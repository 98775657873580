import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable, Subject, timer } from 'rxjs';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { API }  from '@aws-amplify/api';
import axios from 'axios';
import { EUI_CONFIG_TOKEN } from '@eui/core';
import { EuiDialogConfig, EuiDialogService } from '@eui/components/eui-dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AWSAuthService {
    private _enabled = true;
    private _authUser: CognitoUser;
    private _authError: any;
    private _identityProvider: string;

    private _signIn$ = new Subject<CognitoUser>();

    private readonly REFRESH_TOKEN_EXP_MS: number;

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) config,
        private location: Location,
        private euiDialogService: EuiDialogService,
        private translateService: TranslateService,
    ) {
        const envDynConfig = config.environment.loadedEnvDynamicConfig;
        this._signIn$.complete();
        if (envDynConfig.aws) {
            if (envDynConfig.aws.enabled) {
                this.configureAuth(envDynConfig.aws.config);
                this.configureAPI(envDynConfig.aws.config);
                this.REFRESH_TOKEN_EXP_MS = envDynConfig.aws.config?.Auth?.userPoolRefreshTokenExpMs;
            } else {
                this._enabled = false;
            }
        }
    }

    public isEnabled(): boolean {
        return this._enabled;
    }

    public isAuthenticated(): boolean {
        return !!this._authUser;
    }

    public getUsername(): string {
        return this._authUser && this._authUser.getUsername().replace('euLogin_', '');
    }

    public getJwtToken(): string {
        return this._authUser && this._authUser.getSignInUserSession().getIdToken().getJwtToken();
    }

    /**
     * User pool app client refresh token is currently configured to 24h.
     * After max 24h, users need to re-login to obtain a new refresh token.
     * There is no information on the refresh token about the exp time, instead
     * we are using the user authentication start time. The users are warned
     * 1h before the refresh token expration, that they need to re-login.
     */
    private handleExpiration() {
        const authTimeSec = this.getIdTokenPayload()?.auth_time;
        if (authTimeSec == null || this.REFRESH_TOKEN_EXP_MS == null) {
            return;
        }

        const sessionExpInMs = this.REFRESH_TOKEN_EXP_MS - (Date.now() - (authTimeSec * 1000));
        const sessionExpWarnInMs = sessionExpInMs - 3600000;

        // warn about the session expiry
        if (sessionExpWarnInMs > 0) {
            timer(sessionExpWarnInMs).subscribe(() => {
                this.dialogSessionExpired({
                    title: this.translateService.instant('screens.user.session.expired.warn.title'),
                    content: this.translateService.instant('screens.user.session.expired.warn.message'),
                });
            });
        }

        // session expired
        if (sessionExpInMs > 0) {
            timer(sessionExpInMs).subscribe(() => {
                this.dialogSessionExpired({
                    title: this.translateService.instant('screens.user.session.expired.title'),
                    content: this.translateService.instant('screens.user.session.expired.message'),
                    accept: () => this.signOut(),
                });
            });
        }
    }

    private dialogSessionExpired(config: {}) {
        const dialogConfig = new EuiDialogConfig({
            ...config,
            hasCloseButton: false,
            hasDismissButton: false,
            isClosedOnEscape: false,
            acceptLabel: this.translateService.instant('screens.common.message.box.ok.label'),
        });
        this.euiDialogService.openDialog(dialogConfig);
    }

    public getIdTokenPayload(): any {
        return this._authUser?.getSignInUserSession()?.getIdToken()?.decodePayload();
    }

    public signIn(): Observable<CognitoUser> {
        if (!this._signIn$.isStopped) {
            return this._signIn$.asObservable();
        } else {
            this._signIn$ = new Subject<CognitoUser>();
            return new Observable(observer => {
                this._signIn$.subscribe(observer);
                if (this._authUser) {
                    this.signInSuccess(this._authUser);
                } else if (this._authError) {
                    this.signInFail(this._authError);
                } else {
                    if (!this.isAuthCallback(this.location.path(true))) {
                        Auth.currentAuthenticatedUser()
                            .then(user => this.signInSuccess(user))
                            .catch(() => Auth.federatedSignIn({ customProvider: this._identityProvider }));
                    }
                }
                return () => {
                    Hub.remove('auth', this.authCallback);
                };
            });
        }
    }

    public signOut() {
        Auth.signOut();
    }

    public isAuthCallback(url: string): boolean {
        return url && (url.includes('code=') || url.includes('access_token=') || url.includes('error='));
    }

    public removeAuthCallback(url: string): string {
        if (url?.includes('?')) {
            return url.substring(0, url.indexOf('?'));
        } else if (url?.includes('#')) {
            return url.substring(0, url.indexOf('#'));
        }
        return url;
    }

    private configureAuth(config: any) {
        config.Auth.storage = window && window.sessionStorage;
        if (config.Auth.clearStorageOnInit !== false && config.Auth.storage &&
            config.Auth.storage.getItem('amplify-signin-with-hostedUI') != null) {
            config.Auth.storage.clear();
        }
        this._identityProvider = config.Auth.oauth && config.Auth.oauth.identityProvider;
        if (this.isAuthCallback(this.location.path(true))) {
            Hub.listen('auth', this.authCallback);
        }
        Auth.configure(config);
    }

    private configureAPI(config: any) {
        API.configure(config);
        axios.interceptors.request.use(
            request => {
                request.responseType = undefined;
                return request;
            },
        );
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.data != null) {
                    throw {
                        ...error.toJSON(),
                        headers: error.response.headers,
                        message: typeof error.response.data === 'object' ? error.response.data.message ||
                            JSON.stringify(error.response.data) : error.response.data,
                    };
                } else {
                    throw error.toJSON();
                }
            },
        );
    }

    private authCallback = ({ payload }) => {
        if (payload.event === 'signIn') {
            Auth.currentAuthenticatedUser()
                .then(user => this.signInSuccess(user))
                .catch(error => this.signInFail(error));
            Hub.remove('auth', this.authCallback);
        } else if (payload.event.includes('failure')) {
            this.signInFail(payload.message);
            Hub.remove('auth', this.authCallback);
        }
    };

    private signInSuccess(user: CognitoUser) {
        this._authUser = user;
        if (!this._signIn$.isStopped) {
            this._signIn$.next(user);
            this._signIn$.complete();
        }
        this.handleExpiration();
        this.removeLocationAuthCallback();
    }

    private signInFail(error: any) {
        this._authError = error;
        if (!this._signIn$.isStopped) {
            this._signIn$.error(error);
        }
    }

    private removeLocationAuthCallback() {
        const path = this.location.path(true);
        if (this.isAuthCallback(path)) {
            this.location.replaceState(this.removeAuthCallback(path));
        }
    }
}
