import { NgModule } from '@angular/core';
import { DragNDropFileUploadDirective } from './drag-n-drop-file-upload.directive';

import { IfPermissionDirective } from './if-permission.directive';
import { IfRoleDirective } from './if-role.directive';
import { AutocompleteOffDirective } from './autocomplete-off.directive';
import { CardSearchCollapseDirective } from './card-search-collapse.directive';

export const DIRECTIVES = [
    IfPermissionDirective,
    IfRoleDirective,
    DragNDropFileUploadDirective,
    AutocompleteOffDirective,
    CardSearchCollapseDirective,
];

@NgModule({
    declarations: DIRECTIVES,
    exports: DIRECTIVES,
})
export class DirectivesModule {}
