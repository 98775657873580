import { NgModule } from '@angular/core';
import { UxAllModule } from '@eui/components/legacy';
import { EuiAllModule } from '@eui/components';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { RatingModule } from 'primeng/rating';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { CommonModule } from '@angular/common';

const MODULES = [
    CommonModule,
    EuiAllModule,
    UxAllModule,
    TableModule,
    CheckboxModule,
    RatingModule,
    TooltipModule,
    TranslateModule,
    ComponentsModule,
    PipesModule,
    DirectivesModule,
    ZXingScannerModule,
];

@NgModule({
    imports: MODULES,
    declarations: [],
    exports: MODULES,
})
export class SharedModule {
}
