import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasInputChanged, hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../../component/base.component';

@Component({
    selector: 'rtpd-driver-address',
    templateUrl: './address.component.html',
})
export class DriverAddressComponent extends BaseComponent implements OnChanges {

    public worldCountries: EuiAutoCompleteItem[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (hasInputChanged(changes.isReadOnly)) {
            this.refDataLoaded = true;
        }
        if (hasRefDataChanged(changes.refData)) {
            this.worldCountries = this.refData.worldCountries;
            reselectFormControlValue(this.driverAddressCountry, this.worldCountries);
            this.refDataLoaded = true;
        }
    }

    public get driverAddressStreet(): FormControl {
        return this.form.get('driverAddressStreet') as FormControl;
    }

    public get driverAddressPostCode(): FormControl {
        return this.form.get('driverAddressPostCode') as FormControl;
    }

    public get driverAddressCity(): FormControl {
        return this.form.get('driverAddressCity') as FormControl;
    }

    public get driverAddressCountry(): FormControl {
        return this.form.get('driverAddressCountry') as FormControl;
    }
}
