import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { RefDataService } from './refdata.service';
import { UsersService } from '@rtpd/shared';

@Injectable({ providedIn: 'root' })
export class CoreRefDataService {

  public constructor(
        private refDataService: RefDataService,
        private usersService: UsersService,
  ) {
  }

  public get refData$(): Observable<any> {
    return zip(
      this.usersService.self$,
      this.refDataService.formattedMSCountries$,
    ).pipe(
      map((
        [user, countries],
      ) => (
        {
          user: user,
          msCountries: countries,
        }
      )),
    );
  }
}
