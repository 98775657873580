import { Injectable } from '@angular/core';
import { UxAppShellService } from '@eui/core';

@Injectable()
export class GrowlHelper {

    public constructor(private uxAppShellService: UxAppShellService) {
    }

    public growlError(msg: string) {
        this.uxAppShellService.growl({ severity: 'danger', detail: msg }, undefined, undefined, 10000);
    }

    public growlWarning(msg: string) {
        this.uxAppShellService.growl({ severity: 'warning', detail: msg }, undefined, undefined, 10000);
    }

    public growlSuccess(msg: string) {
        this.uxAppShellService.growl({ severity: 'success', detail: msg }, undefined, undefined, 10000);
    }
}
