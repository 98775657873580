import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppShellComponent } from './core/app-shell/app-shell.component';
import { UserProfileComponent } from './core/components/user-profile/user-profile.component';
import { Page404Component } from './core/components/error-pages/page-404/page-404.component';
import { Page500Component } from './core/components/error-pages/page-500/page-500.component';
import { Page403Component } from './core/components/error-pages/page-403/page-403.component';
import { RtpdLandingComponent } from './core/components/landing/landing.component';
import { RtpdHelpComponent } from './core/components/help/help.component';
import { RtpdReleasesComponent } from './core/components/releases/releases.component';
import { SharedModule } from './shared/shared.module';
import { AppStarterService } from './core/services/app-starter.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
    ],
    declarations: [
        AppComponent,
        AppShellComponent,
        Page404Component,
        Page500Component,
        Page403Component,
        UserProfileComponent,
        RtpdLandingComponent,
        RtpdHelpComponent,
        RtpdReleasesComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (appStarterService) => {
                return () =>
                    new Promise<void>((resolve) => {
                        appStarterService.start().subscribe(() => {
                            resolve();
                        });
                    });
            },
            deps: [AppStarterService],
            multi: true,
        },
        AppStarterService,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
