<div class="eui-u-flex eui-u-flex-align-items-center">
    <div class="flex-item">
        <div euiInputGroup class="eui-u-mb-none">
            <div class="eui-input-group-addon">
                <div class="eui-input-group-addon-item">
                    <eui-icon iconClass="eui-icon-search-m"></eui-icon>
                </div>
                <input euiInputText euiClearable class="eui-u-width-20 eui-u-color-grey" [formControl]="searchControl" type="search" placeholder="{{ 'screens.common.search.placeholder' | translate}}"/>
            </div>
        </div>
    </div>
    <ng-container *ngIf="searchControl?.value?.length > 2">
        <div class="flex-item eui-u-ml-m eui-u-color-info-25">
            <span>{{ highlightedIndex }}</span>/<span>{{ foundElements?.size }}</span>
        </div>
        <div class="flex-item eui-u-ml-xs">
            <button euiButton euiIconButton euiBasicButton euiRounded euiPrimary (click)="searchDown()" [disabled]="foundElements?.size === 0"><eui-icon-svg icon="eui-chevron-down"></eui-icon-svg></button>
            <button euiButton euiIconButton euiBasicButton euiRounded euiPrimary (click)="searchUp()" [disabled]="foundElements?.size === 0"><eui-icon-svg icon="eui-chevron-up"></eui-icon-svg></button>
        </div>
    </ng-container>
</div>
