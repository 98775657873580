import { Component } from '@angular/core';
import { BreadcrumbService } from '../../../../core/services/breadcrumb.service';
import { UxLink } from '@eui/core';

@Component({
    selector: 'rtpd-breadcrumb',
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
    public constructor(private breadcrumbService: BreadcrumbService) {
    }

    public get showSidebarLinks(): boolean {
        return this.breadcrumbService.showSidebarLinks;
    }

    public get sidebarLinks(): UxLink[] {
        return this.breadcrumbService.sidebarLinks;
    }

    public get selectedSidebarLink(): UxLink {
        return this.breadcrumbService.selectedSidebarLink;
    }
}
