import { Injectable } from '@angular/core';
import { from, Observable, of, throwError } from 'rxjs';
import API from '@aws-amplify/api';

import { UsersSearchResult } from '../../features/users/models/users/users-search-result.model';

import { SessionStorageService } from '@eui/core';
import { nvl } from '../../core/helpers/null-value.util';
import { catchError, shareReplay } from 'rxjs/operators';
import { convertUser, convertUsers, mapToRequestBody, SearchFilters, User } from '../models';
import { xOperatorId } from '@rtpd/core';

@Injectable({ providedIn: 'root' })
export class UsersService {
    private _api = 'Users';
    private self$Observable: Observable<User>;

    public constructor(
        private storageService: SessionStorageService,
    ) {
    }

    public search(filters: SearchFilters): Observable<UsersSearchResult> {
      return from(API.get(this._api, '', {
        headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
        queryStringParameters: filters,
      })).pipe(convertUsers());
    }

    public clearSelfCache(): void {
        this.self$Observable = null;
    }

    public getSelf(): Observable<User> {
        if (!this.self$Observable) {
            this.self$Observable = from(API.get(this._api, '/self', {})).pipe(
                convertUser(),
                catchError(error => {
                    // 403, 404, CORS/NetworkError (status undefined) handle as unregistered user
                    if (error?.status === 403 || error?.status === 404 || error?.status == null) {
                        return of(new User({ registered: false }));
                    }
                    return throwError(() => error);
                }),
                shareReplay(1), // Cache the result and replay it to new subscribers
            );
        }
        return this.self$Observable;
    }

    public get self$(): Observable<User> {
      return this.getSelf();
    }

    public get(id: string): Observable<User> {
      return from(API.get(this._api, `/${id}`, {
        headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
      })).pipe(convertUser());
    }

    public create(user: User): Observable<User> {
      return from(API.post(this._api, '', {
        body: user,
      }));
    }

    public update(user: User): Observable<User> {
      return from(API.put(this._api, `/${user.userId}`, {
        headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
        body: mapToRequestBody(user),
      }));
    }

    public delete(id: string): Observable<User> {
      return from(API.del(this._api, `/${id}`, {
        headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
      }));
    }
}
