import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { AWSAuthService, UserService } from '@rtpd/core';
import { EUI_CONFIG_TOKEN, I18nService, SessionStorageService } from '@eui/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from '../../../helpers';
import { Subject } from 'rxjs';
import { EuiDialogConfig, EuiDialogService } from '@eui/components/eui-dialog';
import { PathRegistrationProcessVerifier } from '../../../helpers/path-registration-process-verifier.service';
import { TranslateService } from '@ngx-translate/core';
import { isProduction, isTraining } from '@rtpd/shared';

@Component({
    selector: 'rtpd-403',
    templateUrl: './page-403.components.html',
    styleUrls: ['./page-403.component.scss'],
})
export class Page403Component implements OnInit, OnDestroy {
    private readonly _redirect_url = 'rtpd-redirect-url';
    public appVersion: string;
    public privacyStatementLink: any = {
        url: 'assets/privacy/privacy statement en.pdf',
        label: 'screens.footer.privacy.statement',
    };
    public appReleaseDate: string;
    public envName: string;
    public nonProduction: boolean;
    public isTraining: boolean;
    public isIE11 = false;
    public authenticated: boolean;
    public userFullName: string;
    public reloading = false;
    public redirectUrl;
    public isRegistrationProcess = false;
    public accessDenied = false;

    private _onDestroy$ = new Subject<void>();

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
        private authService: AWSAuthService,
        private userService: UserService,
        private storageService: SessionStorageService,
        private pathRegistrationProcessVerifier: PathRegistrationProcessVerifier,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private euiDialogService: EuiDialogService,
        private translateService: TranslateService,
        private i18nService: I18nService,
    ) {
        this.redirectUrl = this.storageService.get(this._redirect_url);
        this.isRegistrationProcess = !isEmpty(this.pathRegistrationProcessVerifier.match(this.redirectUrl));
        this.reloading = this.activatedRoute.snapshot.queryParams['reload'];

        const navigation = this.router.getCurrentNavigation();
        this.accessDenied = navigation?.extras.state?.accessDenied;

        if (this.reloading) {
            this.reload();
        } else if (this.isRegistrationProcess) {
            this.openForwardDialog();
        }

        this.authenticated = this.authService.isEnabled() && this.authService.isAuthenticated();
        this.userFullName = this.userService.getSystemUser() && this.userService.getSystemUser().fullName;
    }

    public ngOnInit() {
        this.nonProduction = !isProduction(this.euConfig.environment.name);
        this.isTraining = isTraining(this.euConfig.environment.name);
        this.envName = this.euConfig.environment.name;
        this.appVersion = this.euConfig.environment.appVersion;
        this.appReleaseDate = this.euConfig.environment.appReleaseDate;
        this.i18nService.getState().subscribe(language => {
            this.privacyStatementLink.url = `assets/privacy/privacy statement ${language.activeLang}.pdf`;
        });
    }

    public signOut() {
        if (this.authService.isAuthenticated()) {
            this.authService.signOut();
        }
    }

    public reload() {
        if (this.isRegistrationProcess || !isEmpty(this.redirectUrl)) {
            this.router.navigateByUrl(this.redirectUrl, { state: { reload: true } })
                .then(() => {
                    return this.windowReload();
                }).catch(() => this.windowReload());
        } else {
            if (!this.accessDenied && this.userService?.getSystemUser()?.registered !== false) {
                this.router.navigate(['/home'], { state: { reload: true } })
                    .then(() => {
                        return this.windowReload();
                    }).catch(() => this.windowReload());
            } else {
                if (window && window.sessionStorage) {
                    window.sessionStorage.clear();
                }
                this.router.navigate(['']);
                setTimeout(() => this.windowReload(), 100);
            }

        }
    }

    public openForwardDialog() {
        const config = new EuiDialogConfig({
            hasCloseButton: false,
            isHandleCloseOnAccept: true,
            isHandleCloseOnDismiss: true,
            title: this.translateService.instant('screens.403.forward.title'),
            content: this.translateService.instant('screens.403.forward.message'),
            width: '50vw',
            dismissLabel: this.translateService.instant('screens.common.message.box.cancel.label'),
            acceptLabel: this.translateService.instant('screens.common.message.box.ok.label'),

            accept: () => {
                this.reload();
                this.euiDialogService.closeDialog();
            },
            dismiss: () => {
                this.reload();
                this.euiDialogService.closeDialog();
            },
            close: () => {
                this.reload();
                this.euiDialogService.closeDialog();
            },
        });

        this.euiDialogService.openDialog(config);
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }

    private windowReload() {
        return window.location.reload();
    }

}
