export const LabelsSection = {
    // a
    postingCountry: 'a.1',
    periodOfPosting: 'a.2',
    typeOfOperation: 'a.3',
    typeOfCarriage: 'a.4',
    // b
    declarationNumber: 'b.1',
    lastUpdate: 'b.2',
    submissionDate: 'b.3',
    withdrawalDate: 'b.4',
    // c
    driverName: 'c.1',
    driverNonLatinName: 'c.2',
    drivingLicence: 'c.3',
    driverCard: 'c.4',
    driverAddress: 'c.5',
    employmentContractStartDate: 'c.6',
    applicableLaw: 'c.7',
    // d
    documentType: 'd.1',
    documentNumber: 'd.2',
    issueDate: 'd.3',
    expiryDate: 'd.4',
    issuingCountry: 'd.5',
    // e
    companyName: 'e.1',
    companyEmail: 'e.2',
    licenceNumber: 'e.3',
    nationalRegisterNumber: 'e.4',
    vatNumber: 'e.5',
    countryOfRegistration: 'e.6',
    companyAddress: 'e.7',
    // f
    transportManagerName: 'f.1',
    transportManagerEmail: 'f.2',
    transportManagerPhone: 'f.3',
    transportManagerAddress: 'f.4',
    certificateOfProfessionalCompetence: 'f.5',
    certificateIssuingCountry: 'f.6',
    // g
    contactPersonName: 'g.1',
    contactPersonEmail: 'g.2',
    contactPersonPhone: 'g.3',
    contactPersonAddress: 'g.4',
};

export const TranslateLabels = {
    title: 'screens.declarations.pdf.title',
    infoAbout: 'screens.declarations.pdf.information.about',
    address: 'screens.declarations.pdf.label.address',
    addressOfResidence: 'screens.declarations.pdf.label.addressOfResidence',
    addressProfessional: 'screens.declarations.pdf.label.addressProfessional',
    name: 'screens.declarations.pdf.label.name',
    emailAddress: 'screens.declarations.pdf.label.email',
    nameNonLatin: 'screens.declarations.pdf.label.name.nonLatin',
    number: 'screens.declarations.pdf.label.number',
    phoneNumber: 'screens.declarations.pdf.label.phone.number',

    // posting information
    postingInformation: 'screens.declarations.pdf.label.posting.information',
    postingCountry: 'screens.declarations.pdf.label.posting.country',
    periodOfPosting: 'screens.declarations.pdf.label.posting.period',
    typeOfOperation: 'screens.declarations.pdf.label.operation.type',
    typeOfCarriage: 'screens.declarations.pdf.label.transport.type',

    // declaration details
    declarationDetails: 'screens.declarations.pdf.label.declaration.details',
    declarationNumber: 'screens.declarations.pdf.label.declaration.number',
    lastUpdate: 'screens.declarations.pdf.label.declaration.last.update',
    submissionDate: 'screens.declarations.pdf.label.declaration.submission.date',
    withdrawalDate: 'screens.declarations.pdf.label.declaration.withdrawal.date',

    // company information
    companyInformation: 'screens.declarations.pdf.label.company.information',
    licenceNumber: 'screens.declarations.pdf.label.company.licence.number',
    nationalRegisterNumber: 'screens.declarations.pdf.label.company.national.registration.number',
    vatNumber: 'screens.declarations.pdf.label.company.vat.number',
    countryOfRegistration: 'screens.declarations.pdf.label.company.country.registration',

    // driver information
    driverInformation: 'screens.declarations.pdf.label.driver.information',
    drivingLicence: 'screens.declarations.pdf.label.driver.license.number',
    driverCard: 'screens.declarations.pdf.label.driver.card',
    employmentContractStartDate: 'screens.declarations.pdf.label.driver.employment.contact.start.date',
    applicableLaw: 'screens.declarations.pdf.label.driver.applicable.law',

    // transport manager information
    transportManager: 'screens.declarations.pdf.label.transport.manager.information',
    certificateOfProfessionalCompetence: 'screens.declarations.pdf.label.transport.manager.certificate',
    certificateIssuingCountry: 'screens.declarations.pdf.label.transport.manager.certificate.issuing.country',

    // driver identification document
    identificationDocument: 'screens.declarations.pdf.label.driver.identification.document',
    documentType: 'screens.declarations.pdf.label.driver.document.type',
    issueDate: 'screens.declarations.pdf.label.driver.document.issue.date',
    expiryDate: 'screens.declarations.pdf.label.driver.document.expiry.date',
    issuingCountry: 'screens.declarations.pdf.label.driver.document.issuing.country',

    // declaration contact person
    contactPerson: 'screens.declarations.pdf.label.contact.person',

    // number plate
    numberPlate: 'screens.declarations.pdf.label.declaration.number.plate',
};
