import { Directive, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({ selector: '[baseComponent]' })
export abstract class BaseComponent {
    @Input() public form: FormGroup;
    @Input() public refData: any;
    @Input() public isReadOnly = false;
    @Input() public showErrors = false;
    public refDataLoaded = false;
}
