import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
    CONFIG_TOKEN,
    EUI_CONFIG_TOKEN,
    SessionStorageService,
    UserState,
    UxAppShellService,
    UxLink,
    I18nService,
} from '@eui/core';
import {
    HistoryLog,
    HistoryLogService,
    isBannerVisible,
    isProduction,
    isTraining,
    Permission,
    SystemUser,
} from '@rtpd/shared';
import { AWSAuthService, CoreDispatchers, CoreSelectors, UserService, xOperatorId } from '@rtpd/core';
import { isEmpty, PathTranslationKeyResolver } from '../helpers';
import { AppShellService } from '../services/app-shell.service';
import { PathSelectCompanyVerifier } from '../helpers/path-select-company.verifier';
import { PathRegistrationVerifier } from '../helpers/path-registration-verifier.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from '../services/breadcrumb.service';

@Component({
    selector: 'rtpd-app-shell',
    templateUrl: './app-shell.component.html',
})
export class AppShellComponent implements OnInit, OnDestroy {

    public notificationLinksWithMetadata: UxLink[] = [];
    public userState$: Observable<UserState>;
    public appVersion: string;
    public appReleaseDate: string;
    public privacyStatementLink: any = { url: 'assets/privacy/privacy statement en.pdf', label: 'screens.footer.privacy.statement' };
    public envName: string;
    public nonProduction: boolean;
    public isTraining: boolean;
    public showBanner: boolean;
    public isIE11 = false;

    public showOperator = false;
    public unReadNotifications: number;

    public allowSelectCompanyAccount: boolean;

    private _onDestroy$ = new Subject<void>();

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
        @Inject(CONFIG_TOKEN) private config,
        private router: Router,
        private uxAppShellService: UxAppShellService,
        private coreSelectors: CoreSelectors,
        private coreDispatchers: CoreDispatchers,
        private authService: AWSAuthService,
        private userService: UserService,
        private historyLogService: HistoryLogService,
        private pathTranslationKeyResolver: PathTranslationKeyResolver,
        private storageService: SessionStorageService,
        private appShellService: AppShellService,
        private pathSelectCompanyVerifier: PathSelectCompanyVerifier,
        private pathRegistrationVerifier: PathRegistrationVerifier,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.allowSelectCompanyAccount = !this.pathSelectCompanyVerifier.match(this.router.url);
    }

    public ngOnInit() {
        this.nonProduction = !isProduction(this.euConfig.environment.name);
        this.envName = this.euConfig.environment.name;
        this.isTraining = isTraining(this.euConfig.environment.name);
        this.showBanner = isBannerVisible('screens.landing.top.message.production.notification',
            this.translateService,
            this.euConfig);
        this.appVersion = this.euConfig.environment.appVersion;
        this.appReleaseDate = this.euConfig.environment.appReleaseDate;
        this.userState$ = this.coreSelectors.userState$;
        this.setSelectedSidebarLink(this.router.url);

        this.uxAppShellService.state$.pipe(
            takeUntil(this._onDestroy$),
        ).subscribe((state: any) => {
            if (state.deviceInfo && state.deviceInfo.isIE11) {
                this.isIE11 = true;
            }
        });
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._onDestroy$),
        ).subscribe((event: NavigationEnd) => {
            this.setSelectedSidebarLink(event.url);
        });
        this.coreDispatchers.updateUserDetails(this.userService.getSystemUser());
        this.subscribeOnSystemUserUpdate();
        this.subscribeShowOperatorUpdate();
        this.getNotifications();
        this.translateService.onLangChange.subscribe(() => {
            this.rebuildSidebarLinks();
        });
        this.i18nService.getState().subscribe(language => {
            this.privacyStatementLink.url = `assets/privacy/privacy statement ${language.activeLang}.pdf`;
        });
    }

    public setSelectedSidebarLink(url: string) {
        // Update the breadcrumb service's selectedSidebarLink
        this.breadcrumbService.selectedSidebarLink = this.breadcrumbService.sidebarLinks
            .reduce((links, link) => links.concat(link), [])
            .find(link => url && link && url.indexOf(link.url) > -1);

        if (this.breadcrumbService.selectedSidebarLink && this.breadcrumbService.selectedSidebarLink.isHome) {
            this.uxAppShellService.isSidebarOpen = true;
        } else if (this.breadcrumbService.selectedSidebarLink) {
            if (url && url.length > 0 && url.slice(1).includes('/')) {
                this.breadcrumbService.selectedSidebarLink.metadata = [
                    new UxLink({ label: this.pathTranslationKeyResolver.resolve(url) }),
                ];
            } else {
                this.breadcrumbService.selectedSidebarLink.metadata = null;
            }
            setTimeout(() => this.uxAppShellService.isSidebarOpen = false, 200);
        } else {
            this.breadcrumbService.sidebarLinks = [];
        }

        const registrationPath = this.pathRegistrationVerifier.match(url);
        this.breadcrumbService.showSidebarLinks =
            this.breadcrumbService.selectedSidebarLink && !registrationPath ? true : false;
        this.showOperator = !registrationPath;
    }

    public profile() {
        this.coreDispatchers.toggleUserProfile();
    }

    public signOut() {
        this.authService.signOut();
    }

    public selectCompanyAccount() {
        if (this.allowSelectCompanyAccount) {
            this.router.navigate(['account/select']);
            this.breadcrumbService.showSidebarLinks = false;
        }
    }

    public createCompanyAccount() {
        this.router.navigate(['account/create']);
        this.breadcrumbService.showSidebarLinks = false;
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }

    public onNotificationsMarkAllAsReadClick(): void {
        this.notificationLinksWithMetadata.forEach(notification => notification.metadata.read = true);
        this.unReadNotifications = 0;
        this.historyLogService.seenHistoryLogs().subscribe(() => this.refreshNotifications());
    }

    public onNotificationsRefreshClick(): void {
        this.refreshNotifications();
    }

    public get sidebarLinks(): UxLink[] {
        return this.breadcrumbService.sidebarLinks; // Access sidebarLinks from BreadcrumbService
    }

    public get showSidebarLinks(): boolean {
        return this.breadcrumbService.showSidebarLinks; // Access showSidebarLinks from BreadcrumbService
    }

    private rebuildSidebarLinks() {
        this.breadcrumbService.sidebarLinks = this.buildSidebarLinks();
        this.setSelectedSidebarLink(this.router.url);
    }

    private buildSidebarLinks(): UxLink[] {
        return [
            ...(this.userService.hasPermissions([Permission.ViewHome]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.home.title'),
                    iconClass: 'fa fa-home',
                    url: '/home',
                    isHome: true,
                })] : [])
            ,
            ...(this.userService.hasPermissions([Permission.ViewDeclarations]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.declarations.title'),
                    iconClass: 'fa fa-file-text',
                    url: '/declarations',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewDrivers]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.drivers.title'),
                    iconClass: 'fa fa-bus',
                    url: '/drivers',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewRequests]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.requests.title'),
                    iconClass: 'fa fa-comments',
                    url: '/requests',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewUsers]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.user.management.title'),
                    iconClass: 'fa fa-users',
                    url: '/users',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewAccount]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.account.title'),
                    iconClass: 'fa fa-cog',
                    url: '/account',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewApiKey]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.apikey.title'),
                    iconClass: 'fa fa-lock',
                    url: '/apikey',
                })] : []),
            ...(this.userService.hasPermissions([Permission.ViewHelp]) ?
                [new UxLink({
                    label: this.translateService.instant('screens.help.title'),
                    urlExternal: '/help',
                    iconClass: 'fa fa-question-circle',
                })] : []),
        ];
    }

    private subscribeOnSystemUserUpdate() {
        this.userService.onSystemUserUpdate().subscribe(systemUser => {
            if (this.authService.isAuthenticated()) {
                if (this.isAccountUnregistered(systemUser) && this.allowSelectCompanyAccount) {
                    // the user attempts to access unregisterd or deleted account
                    this.router.navigate(['403']);
                } else if (this.isCompanySelectionNeeded(systemUser) && this.allowSelectCompanyAccount) {
                    this.storageService.set('rtpd-forward-url', this.router.url);
                    this.router.navigate(['account/select']);
                    this.breadcrumbService.showSidebarLinks = false;
                } else {
                    this.rebuildSidebarLinks();
                    this.refreshNotifications();
                }
                this.coreDispatchers.updateUserDetails(systemUser);
            }
        });
    }

    private isAccountUnregistered(systemUser: SystemUser) {
        return systemUser?.sub == null && systemUser?.registered === false;
    }

    private isCompanySelectionNeeded(systemUser: SystemUser) {
        return systemUser?.operatorDetails?.length > 1 && !systemUser?.operatorId;
    }

    private subscribeShowOperatorUpdate() {
        this.appShellService.onShowOperatorUpdate().subscribe(showOperator => {
            if (this.authService.isAuthenticated()) {
                this.showOperator = showOperator;
            }
        });
    }

    private mapNotificationsToUxLink(historyLogs: HistoryLog[]): UxLink[] {
        return historyLogs.map(historyLog => {
            return {
                label: this.historyLogService.constructHistoryLogTitle(historyLog),
                metadata: {
                    date: new Date(parseInt(historyLog.createdOn)),
                    read: historyLog.seen,
                    notificationObj: historyLog,
                },
            } as UxLink;
        });
    }

    private getNotifications(): void {
        timer(0, 60000).pipe(takeUntil(this._onDestroy$)).subscribe(() => this.refreshNotifications());
    }

    private refreshNotifications() {
        const operatorId = this.storageService.get(xOperatorId);
        if (!isEmpty(operatorId)) {
            this.historyLogService.getAll().pipe(takeUntil(this._onDestroy$)).subscribe((notifications: any) => {
                this.notificationLinksWithMetadata = this.mapNotificationsToUxLink(notifications);
                this.unReadNotifications = notifications.filter(notification => {
                    return !notification.seen;
                }).length;
            });
        }
    }
}
