import { Injectable } from '@angular/core';
import { Store, select, createFeatureSelector, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as accountReducers from '../reducers/account.reducers';
import { Account } from '../models/account';
import { HistoryLog } from '@rtpd/shared';
import { Search } from '../../declarations/models';

/* Selectors */

const getState = createFeatureSelector<accountReducers.State>(accountReducers.namespace);

const getLoading = createSelector(
  getState,
  (state: accountReducers.State) => state.loading,
);

const getRefDataLoaded = createSelector(
    getState,
    (state: accountReducers.State) => state.refDataLoaded,
);

const getSelectedAccount = createSelector(
  getState,
  (state: accountReducers.State) => state.selectedAccount,
);

const getCreated = createSelector(
  getState,
  (state: accountReducers.State) => state.created,
);

const getHistoryLogAccount = createSelector(
    getState,
    (state: accountReducers.State) => state.historyLogs,
);

const getDeclarationsSearch = createSelector(
    getState,
    (state: accountReducers.State) => state.searchDeclarations,
);

const getIsDeletable = createSelector(
    getState,
    (state: accountReducers.State) => state.isDeletable,
);

@Injectable({ providedIn: 'root' })
export class AccountSelectors {

  public constructor(
        private store: Store<accountReducers.State>,
  ) {}

  public get loading$(): Observable<boolean> {
    return this.store.pipe(select(getLoading));
  }

    public get refDataLoaded$(): Observable<boolean> {
        return this.store.pipe(select(getRefDataLoaded));
    }

  public get selectedAccount$(): Observable<Account> {
    return this.store.pipe(select(getSelectedAccount));
  }

  public get created$(): Observable<boolean> {
    return this.store.pipe(select(getCreated));
  }

  public get accountHistoryLog$(): Observable<HistoryLog[]> {
    return this.store.pipe(select(getHistoryLogAccount));
  }

  public get declarationsSearch$(): Observable<Search> {
    return this.store.pipe(select(getDeclarationsSearch));
  }

  public get isDeletable$(): Observable<boolean> {
    return this.store.pipe(select(getIsDeletable));
  }

}
