import { Action } from '@ngrx/store';

import { Error } from '@rtpd/shared';

export const HANDLE_ERROR = '[Economic Operator Core] Handle error';

export const NAVIGATE = '[Economic Operator Core] Navigate';

export const SIGN_OUT = '[Economic Operator Core] Sign Out';

export class HandleErrorAction implements Action {
    public readonly type = HANDLE_ERROR;

    public constructor(public payload: Error, public skip = false ) {
    }
}

export class NavigateAction implements Action {
    public readonly type = NAVIGATE;

    public constructor(public payload: { route: string[]; state?: any }) {
    }
}

export class SignOutAction implements Action {
    public readonly type = SIGN_OUT;

}

export type Actions =
    HandleErrorAction |
    NavigateAction |
    SignOutAction ;
