import { Injectable } from '@angular/core';
import { isEmpty } from './null-value.util';

@Injectable()
export class PathSelectCompanyVerifier {

    public pathList: Array<string> = [
        '/account/create',
        '/registration',
    ];

    public match(path: string): string {
        if (isEmpty(path)) {
            return '';
        }
        return this.pathList.find(template => path.includes(template));
    }

}
