import { NgModule } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { UxAllModule } from '@eui/components/legacy';
import { EuiAllModule } from '@eui/components';
import { TranslateModule } from '@ngx-translate/core';

import { TextAutofitComponent } from './text-autofit/text-autofit.component';
import { TermSearchComponent } from './term-search/term-search.component';
import { MultiplierTextComponent } from './multipliable-text/multiplier-text.component';

import { CompanyInformationComponent } from './company/information/company-information.component';
import { CompanyAddressComponent } from './company/address/company-address.component';
import { TransportManagerInformationComponent } from './transport-manager/information/transport-manager-information.component';
import { TransportManagerAddressComponent } from './transport-manager/address/transport-manager-address.component';
import {
    DriverAddressComponent,
    DriverContractComponent,
    DriverDetailsComponent,
    DriverDocumentComponent,
} from './driver';
import { FilesUploadComponent } from './files-upload/files-upload.component';
import { FileComponent } from './files-upload/file/file.component';
import { ShowDateOrDatepickerComponent } from './show-date-or-datepicker/show-date-or-datepicker.component';
import { HistoryLogComponent } from './history-log/history-log.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { PageSearchComponent } from './page-search/page-search.component';

export const COMPONENTS = [
    FilesUploadComponent,
    FileComponent,
    TermSearchComponent,
    TextAutofitComponent,
    MultiplierTextComponent,
    CompanyInformationComponent,
    CompanyAddressComponent,
    TransportManagerInformationComponent,
    TransportManagerAddressComponent,
    DriverAddressComponent,
    DriverContractComponent,
    DriverDetailsComponent,
    DriverDocumentComponent,
    FilesUploadComponent,
    ShowDateOrDatepickerComponent,
    HistoryLogComponent,
    BreadcrumbComponent,
    PageSearchComponent,
];

@NgModule({
    imports: [
        EuiAllModule,
        TooltipModule,
        TranslateModule,
        UxAllModule,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class ComponentsModule {
}
