export class UserDetails {
    public userId: string;
    public email: string;
    public firstName: string;
    public lastName: string;
    public phone?: string;
    public createdBy?: string;
    public createdOn?: Date;
    public modifiedOn?: Date;

    public constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
