import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Permission } from '../models';
import { UserService } from '../../core/services/user.service';

@Directive({
  selector: '[rtpdIfPermission]',
})
export class IfPermissionDirective {
    @Input()
  public set rtpdIfPermission(permission: Permission | Permission[]) {
    this.updateViewForPermissions(permission && [].concat(permission));
  }

    @Input()
    public set rtpdIfPermissionElse(templateRef: TemplateRef<any>) {
      this.updateViewForElse(templateRef);
    }

    @Input()
    public set rtpdAuthorized(authorized: boolean) {
      this.updateViewForAuthorized(authorized);
    }

    public constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userService: UserService,
    ) {
    }

    private updateViewForPermissions(permissions: Permission[]) {
      if (!permissions || this.userService.hasPermissions(permissions)) {
        this.createView();
      } else {
        this.clearView();
      }
    }

    private updateViewForElse(templateRef: TemplateRef<any>) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(templateRef);
      }
    }

    private updateViewForAuthorized(authorized: boolean) {
      if (authorized) {
        this.createView();
      } else {
        this.clearView();
      }
    }

    private createView() {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }

    private clearView() {
      if (this.viewContainer.length > 0) {
        this.viewContainer.clear();
      }
    }
}
