import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum ErrorCode {
    ITEM_NOT_FOUND = 108,
    MISSING_HEADER = 109,
    ILLEGAL_ARGUMENT = 110,
    DECLARATIONS_SUBMITTED_ONLY = 208,
}

@Injectable()
export class ApiErrorHelper {

    private readonly errors = new Map<number, string>([
        [ ErrorCode.ITEM_NOT_FOUND, 'error.item.not.found' ],
        [ ErrorCode.ILLEGAL_ARGUMENT, 'error.missing.argument' ],
        [ ErrorCode.MISSING_HEADER, 'error.missing.header' ],
        [ ErrorCode.DECLARATIONS_SUBMITTED_ONLY, 'error.declarations.only.submitted.allowed.by.email' ],
    ]);

    public constructor(private translateService: TranslateService) {
    }

    public parse(message: any) {
        let errorMessage = message;
        try {
            const error = JSON.parse(message);
            if (error.errorCode) {
                errorMessage = this.translate(error.errorCode, error.params) || error.errorMessage;
            }
        } catch(error) {
            // Not a json so just treat the message as a normal error message
        }
        return errorMessage;
    }

    private translate(code: number, params?: any) {
        const key = this.errors.get(code);
        return this.translateService.instant(key || 'error.unexpected.error', params);
    }
}
