import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Releases } from '@rtpd/shared';

@Injectable({ providedIn: 'root' })
export class ReleasesService {

    public constructor(
        private httpClient: HttpClient,
    ) {}

    public getReleases(): Observable<Releases> {
        return this.httpClient.get<Releases>('assets/releases/releases.json');
    }
}
