import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { UxAppShellService } from '@eui/core';

import { CoreRefDataService } from './core-refdata.service';
import { GrowlHelper } from './growl-helper.service';

@Injectable({ providedIn: 'root' })
export class CoreRefDataResolver implements Resolve<Observable<any>> {

    public constructor(
        private router: Router,
        private uxAppShellService: UxAppShellService,
        private growlHelper: GrowlHelper,
        private coreRefData: CoreRefDataService,
    ) {
    }

    public resolve(): Observable<any> {
        this.uxAppShellService.isBlockDocumentActive = true;
        return this.coreRefData.refData$.pipe(
            finalize(() => {
                this.uxAppShellService.isBlockDocumentActive = false;
            }),
            catchError(error => {
                this.growlHelper.growlError(`RefData remote service error: ${error.message || JSON.stringify(error)}`);
                this.router.navigate(['500']);
                return throwError(() => error);
            }),
        );
    }
}
