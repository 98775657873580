import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { UsersService } from '@rtpd/shared';

import { RefDataService } from '../../../core/services/refdata.service';

@Injectable({ providedIn: 'root' })
export class AccountRefDataService {

    public constructor(
        private refDataService: RefDataService,
        private usersService: UsersService,
    ) {
    }

    public get refData$(): Observable<any> {
        return zip(
            this.usersService.self$,
            this.refDataService.formattedMSCountries$,
            this.refDataService.formattedMSUKCountries$,
        ).pipe(
            map((
                [user, msCountries, postingCountries],
            ) => (
                {
                    user: user,
                    msCountries: msCountries,
                  postingCountries: postingCountries,
                }
            )),
        );
    }
}
