import { Injectable } from '@angular/core';
import { UxLink } from '@eui/core';

@Injectable()
export class BreadcrumbService {
    private _sidebarLinks: UxLink[] = [];
    private _showSidebarLinks = false;
    private _selectedSidebarLink: UxLink;

    public get sidebarLinks(): UxLink[] {
        return this._sidebarLinks;
    }

    public set sidebarLinks(links: UxLink[]) {
        this._sidebarLinks = links;
    }

    public get showSidebarLinks(): boolean {
        return this._showSidebarLinks;
    }

    public set showSidebarLinks(show: boolean) {
        this._showSidebarLinks = show;
    }

    public get selectedSidebarLink(): UxLink {
        return this._selectedSidebarLink;
    }

    public set selectedSidebarLink(link: UxLink) {
        this._selectedSidebarLink = link;
    }
}
