import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CONFIG_TOKEN, EUI_CONFIG_TOKEN, I18nService } from '@eui/core';
import { TranslateService } from '@ngx-translate/core';
import { EuiDialogConfig, EuiDialogService } from '@eui/components/eui-dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { isBannerVisible, isProduction, isTraining } from '@rtpd/shared';

@Component({
    selector: 'rtpd-landing-component',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RtpdLandingComponent implements OnInit, OnDestroy {

    public appVersion: string;
    public appReleaseDate: string;
    public privacyStatementLink: any = { url: 'assets/privacy/privacy statement en.pdf', label: 'screens.footer.privacy.statement' };
    public envName: string;
    public nonProduction: boolean;
    public isTraining: boolean;
    public showBanner: boolean;
    public isIE11 = false;
    private _onDestroy$ = new Subject<void>();

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
        @Inject(CONFIG_TOKEN) private config,
        private euiDialogService: EuiDialogService,
        private translateService: TranslateService,
        private router: Router,
        private i18nService: I18nService,
    ) {
    }

    public ngOnInit() {
        this.nonProduction = !isProduction(this.euConfig.environment.name);
        this.isTraining = isTraining(this.euConfig.environment.name);
        this.showBanner = isBannerVisible('screens.landing.top.message.production.notification',
            this.translateService,
            this.euConfig);
        this.envName = this.euConfig.environment.name;
        this.appVersion = this.euConfig.environment.appVersion;
        this.appReleaseDate = this.euConfig.environment.appReleaseDate;
        this.i18nService.getState().subscribe(language => {
            this.privacyStatementLink.url = `assets/privacy/privacy statement ${language.activeLang}.pdf`;
        });
    }

    public openDialog() {
        const config = new EuiDialogConfig({
            hasCloseButton: false,
            isHandleCloseOnAccept: true,
            isHandleCloseOnDismiss: true,
            title: this.translateService.instant('screens.landing.not.logged.button.create.account'),
            content: this.translateService.instant('screens.landing.not.logged.dialog.message'),
            width: '50vw',
            dismissLabel: this.translateService.instant('screens.landing.not.logged.button.cancel'),
            acceptLabel: this.translateService.instant('screens.landing.not.logged.button.continue'),
            accept: () => {
                this.router.navigate(['account/create']);
                this.euiDialogService.closeDialog();
            },
            dismiss: () => {
                this.euiDialogService.closeDialog();
            },
        });

        this.euiDialogService.openDialog(config);
    }

    public get title(): string {
        return 'screens.landing.not.logged.button.create.account';
    }

    public get message(): string {
        return 'screens.landing.not.logged.dialog.message';
    }

    public get dismissButton(): string {
        return 'screens.landing.not.logged.button.cancel';
    }

    public get acceptButton(): string {
        return 'screens.landing.not.logged.button.continue';
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }
}

