import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { EUI_CONFIG_TOKEN, I18nService, UxAppShellService, SessionStorageService } from '@eui/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LabelsSection, TranslateLabels } from '../../../shared/models/help/help';
import { isBannerVisible, isProduction, isTraining } from '@rtpd/shared';

@Component({
    selector: 'rtpd-help-component',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class RtpdHelpComponent implements OnInit, OnDestroy {
    public appVersion: string;
    public appReleaseDate: string;
    public privacyStatementLink: any = {
        url: 'assets/privacy/privacy statement en.pdf',
        label: 'screens.footer.privacy.statement',
    };
    public envName: string;
    public nonProduction: boolean;
    public isTraining: boolean;
    public showBanner: boolean;
    public isIE11 = false;
    private _onDestroy$ = new Subject<void>();
    public FAQ_ITEMS_ARRAY = new Array(100);
    public searchText: string;
    public showReleasesSection = true;
    public showMoreReleases = true;

    public glossarySection = LabelsSection;
    public glossaryLabel = TranslateLabels;

    public constructor(
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
        private i18nService: I18nService,
        private translateService: TranslateService,
        private uxAppShellService: UxAppShellService,
        private storageService: SessionStorageService,
    ) {
    }

    public ngOnInit(): void {
        this.nonProduction = !isProduction(this.euConfig.environment.name);
        this.isTraining = isTraining(this.euConfig.environment.name);
        this.showBanner = isBannerVisible('screens.landing.top.message.production.notification',
            this.translateService,
            this.euConfig);
        this.envName = this.euConfig.environment.name;
        this.appVersion = this.euConfig.environment.appVersion;
        this.appReleaseDate = this.euConfig.environment.appReleaseDate;

        this.i18nService.getState()
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(language => {
                const activeLang = this.storageService.get('activeLang');
                if (activeLang) {
                    this.translateService.use(activeLang);
                }

                this.privacyStatementLink.url = `assets/privacy/privacy statement ${language.activeLang}.pdf`;
            });

        this.i18nService.onStateChange
            .pipe(takeUntil(this._onDestroy$))
            .subscribe(language => {
                // page-search is manipulating the DOM hence need to realod the translations
                const activeLang = this.storageService.get('activeLang');
                if (activeLang) {
                    this.storageService.remove('activeLang');
                } else {
                    if (language.activeLang !== this.translateService.defaultLang) {
                        this.storageService.set('activeLang', language.activeLang);
                    }
                    window?.location?.reload();
                }
            });

        this.uxAppShellService.state$
            .pipe(takeUntil(this._onDestroy$))
            .subscribe((state: any) => {
                if (state.deviceInfo && state.deviceInfo.isIE11) {
                    this.isIE11 = true;
                }
            });

        this.transformLabels(TranslateLabels);
    }

    public navToSection(sectionId: string) {
        const element = document.getElementById(sectionId);
        const top = element.getBoundingClientRect().top + window.scrollY - 155;
        window.scrollTo({ top, behavior: 'smooth' });
    }

    public ngOnDestroy() {
        this._onDestroy$.next();
    }

    private transformLabels(translateLabels) {
        Object.keys(translateLabels).forEach(key => this.glossaryLabel[key] = translateLabels[key] + '.help');
    }
}
