<div class="notranslate" *ngIf="userState$ | async as user">
    <eui-app [userInfos]="user.fullName || user.firstName || user.userId"
             [userSubInfos]="user?.operatorName? user?.operatorName : ''" isSidebarOpen=isSidebarOpen>
        <eui-app-top-message *ngIf="isTraining" color="error" [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.training.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-top-message *ngIf="showBanner" euiWarning [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.production.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-header>
            <eui-header>
                <eui-header-logo></eui-header-logo>
                <eui-header-environment *ngIf="nonProduction">{{ envName }}</eui-header-environment>
                <eui-header-app>
                    <eui-header-app-name class="appname">{{ 'application.title.full' | translate }}
                    </eui-header-app-name>
                </eui-header-app>
                <eui-header-user-profile>
                    <eui-user-profile-menu>
                        <eui-user-profile-menu-item (click)="profile()">
                            <eui-icon iconClass="eui-icon-person-thin"></eui-icon>
                            {{ 'screens.header.profile.menu.user.details.title' | translate }}
                        </eui-user-profile-menu-item>
                        <eui-user-profile-menu-item *ngIf="allowSelectCompanyAccount" (click)="selectCompanyAccount()">
                            <eui-icon iconClass="eui-icon-swap-horizontal"></eui-icon>
                            {{ 'screens.header.profile.menu.switch.account.title' | translate }}
                        </eui-user-profile-menu-item>
                        <eui-user-profile-menu-item (click)="createCompanyAccount()">
                            <eui-icon iconClass="eui-icon-add-circle-o"></eui-icon>
                            {{ 'screens.account.create.title' | translate }}
                        </eui-user-profile-menu-item>
                        <eui-user-profile-menu-item (click)="signOut()">
                            <eui-icon iconClass="eui-icon-logout-thin"></eui-icon>
                            {{ 'screens.header.profile.menu.sign.out.title' | translate }}
                        </eui-user-profile-menu-item>
                    </eui-user-profile-menu>
                </eui-header-user-profile>
            </eui-header>
        </eui-app-header>

        <eui-app-toolbar>
            <eui-toolbar>
                <eui-toolbar-items *ngIf="showSidebarLinks">
                    <eui-toolbar-item-notifications>
                        <eui-notifications
                            [count]="unReadNotifications" [items]="notificationLinksWithMetadata"
                            [nbUnreadCount]="unReadNotifications"
                            [isShowRefreshButton]="true"
                            [isShowSettingsButton]="false"
                            [isShowMarkAllAsReadButton]="unReadNotifications > 0"
                            [isShowMarkAsRead]="false"
                            [isShowViewAllAction]="false"
                            [isHidePanelOnViewAllAction]="false"
                            headerTitleLabel="{{ 'screens.header.notifications.header.title' | translate }}"
                            markAllAsReadLabel="{{ 'screens.header.notifications.header.button.mark.all.read' | translate }}"
                            refreshLabel="{{ 'screens.header.notifications.header.button.refresh' | translate }}"
                            noNotificationFoundLabel="{{ 'screens.header.notifications.footer.label.not.found' | translate }}"
                            (markAllAsReadClick)="onNotificationsMarkAllAsReadClick()"
                            (refreshClick)="onNotificationsRefreshClick()">
                        </eui-notifications>
                    </eui-toolbar-item-notifications>
                </eui-toolbar-items>
            </eui-toolbar>
        </eui-app-toolbar>

        <eui-app-sidebar *ngIf="showSidebarLinks">
            <eui-app-sidebar-body>
                <eui-app-sidebar-menu [items]="sidebarLinks" [hasFilter]="false" [hasIcons]="true">
                </eui-app-sidebar-menu>
            </eui-app-sidebar-body>
        </eui-app-sidebar>

        <eui-app-footer>
            <eui-footer>
                <div class="col-12  text-center">
                    <div class="flex-container  justify-content-around">
                        <span>
                            <span *ngIf="appReleaseDate">{{ 'screens.common.version.label' | translate }}
                                <strong>{{ appVersion }}</strong> - {{ appReleaseDate }}</span>
                            <span *ngIf="!appReleaseDate">{{ 'screens.common.version.label' | translate }}
                                <strong>{{ appVersion }}</strong></span>
                            <span>
                                |
                                <a *ngIf="privacyStatementLink.url" target="_blank" rel="noopener"
                                   href="{{ privacyStatementLink.url }}">
                                    {{ privacyStatementLink.label | translate }}
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
            </eui-footer>
        </eui-app-footer>
    </eui-app>
</div>

<ng-container *ngIf="isIE11">
    <div>
        <strong>{{ 'screens.common.warning.label' | translate }} </strong> - {{
        'screens.common.obsolete.browser.message' | translate }}
    </div>
</ng-container>

<ng-container *ngIf="userState$ | async as user">
    <rtpd-user-profile *ngIf="user.isUserProfileOpen" [userState]="user"></rtpd-user-profile>
</ng-container>
