import { HistoryLog } from '@rtpd/shared';
import * as accountActions from '../actions/account.actions';
import { Account } from '../models/account';
import * as declarationsActions from '../../declarations/actions/declarations.actions';
import { isAdvancedFiltersApplied, Search } from '../../declarations/models';
export const namespace = 'account';

/* State */

export interface State {
    refDataLoaded: boolean;
    loading: boolean;
    selectedAccount: Account;
    created: boolean;
    historyLogs: HistoryLog[];
    searchDeclarations: Search;
    isDeletable: boolean;
}

export const initialState: State = {
  refDataLoaded: false,
  loading: false,
  selectedAccount: null,
  created: false,
  historyLogs: null,
  searchDeclarations: null,
  isDeletable: false,
};

/* Reducers */

export const getAccount = (state: State) => {
  return {
    ...state,
    loading: true,
    selectedAccount: null,
  };
};

export const getAccountSuccess = (state: State, action: accountActions.GetAccountSuccessAction) => {
  return {
    ...state,
    loading: false,
    selectedAccount: action.payload,
  };
};

export const getHistoryLogAccoutSuccess = (state: State, action: accountActions.GetHistoryLogAccountSuccessAction) => {
    return {
      ...state,
      historyLogs: action.payload,
    };
  };

const endLoadingAction = (state: State): State => {
  return {
    ...state,
    loading: false,
  };
};

const refDataLoadedAction = (state: State): State => {
    return {
        ...state,
        refDataLoaded: true,
    };
};

const accountCreated = (state: State): State => {
  return {
    ...state,
    loading: false,
    created: true,
  };
};

export const initAccount = (state: State) => {
  return {
    ...state,
    selectedAccount: null,
  };
};

export const createAccount = (state: State) => {
  return {
    ...state,
    selectedAccount: { ...state.selectedAccount },
    loading: true,
  };
};

export const updateAccount = (state: State) => {
    return {
        ...state,
        selectedAccount: { ...state.selectedAccount },
        loading: true,
    };
};

export const changeAccount = (state: State, action: accountActions.ChangeAccountAction) => {
  return {
    ...state,
    selectedAccount: action.payload,
  };
};

export const searchDeclarationsAction = (state: State, action: declarationsActions.SearchDeclarationsAction) => {
    return {
        ...state,
        loading: true,
        searchDeclarations: {
            isAdvancedFiltersApplied: isAdvancedFiltersApplied(action.payload),
            filters: action.payload,
        },
        isDeletable: false,
    };
};

const startLoadingAction = (state: State): State => {
    return {
        ...state,
        loading: true,
    };
};

export const searchDeclarationsSuccess = (state: State, action: declarationsActions.SearchDeclarationsSuccessAction) => {
    return {
        ...state,
        loading: false,
        searchDeclarationsEntities: action.payload.reduce((entities, declaration) => {
            return {
                ...entities,
                [declaration.declarationId]: declaration,
            };
        },
            {}),
        isDeletable: action.payload.length === 0,
    };
};

export const searchDeclarationsFail = (state: State) => {
    return {
        ...state,
        loading: false,
        isDeletable: false,
    };
};

const actionToReducerMap = {
  [accountActions.GET_ACCOUNT]: getAccount,
  [accountActions.GET_ACCOUNT_SUCCESS]: getAccountSuccess,
  [accountActions.GET_ACCOUNT_FAIL]: endLoadingAction,
  [accountActions.CREATE_ACCOUNT]: createAccount,
  [accountActions.CREATE_ACCOUNT_SUCCESS]: accountCreated,
  [accountActions.CREATE_ACCOUNT_FAIL]: endLoadingAction,
  [accountActions.DELETE_ACCOUNT]: startLoadingAction,
  [accountActions.DELETE_ACCOUNT_SUCCESS]: endLoadingAction,
  [accountActions.DELETE_ACCOUNT_FAIL]: endLoadingAction,
  [accountActions.SEARCH_DECLARATIONS]: searchDeclarationsAction,
  [accountActions.SEARCH_DECLARATIONS_SUCCESS]: searchDeclarationsSuccess,
  [accountActions.SEARCH_DECLARATIONS_FAIL]: searchDeclarationsFail,
  [accountActions.CHANGE_ACCOUNT]: changeAccount,
  [accountActions.UPDATE_ACCOUNT]: updateAccount,
  [accountActions.UPDATE_ACCOUNT_SUCCESS]: endLoadingAction,
  [accountActions.UPDATE_ACCOUNT_FAIL]: endLoadingAction,
  [accountActions.REF_DATA_LOADED]: refDataLoadedAction,
  [accountActions.GET_HISTORY_LOG_ACCOUNT_SUCCESS]: getHistoryLogAccoutSuccess,
};

export function reducer(state: State = initialState, action: accountActions.Actions): State {
  if (actionToReducerMap[action.type]) {
    return (<any>actionToReducerMap[action.type])(state, action);
  }
  return state;
}
