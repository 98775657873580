<div class="eui-u-mh-2xl eui-u-mv-l" *ngIf="releases?.length">
    <eui-timeline>
        <eui-timeline-item [date]="release.title" [time]="release.version" [styleClass]="release.style || 'default'" *ngFor="let release of releases">
            <div class="eui-u-pb-s" *ngFor="let note of release.notes">
                <div>
                    <span class="eui-u-font-s" [ngClass]="note.style ? 'eui-u-color-' + note.style : 'eui-u-color-grey'"><strong>{{ note.title }}</strong></span>
                </div>
                <ul class="eui-u-pl-m eui-u-font-s eui-u-color-grey-75" *ngFor="let item of note.items">
                    <li class="eui-u-pt-xs">
                        - {{ item }}
                    </li>
                </ul>
            </div>
        </eui-timeline-item>
    </eui-timeline>

    <button euiButton euiAccent euiSizeS class="eui-u-ml-l eui-u-mt-2xl" (click)="toggleShowMore()">
        <span euiLabel>
            <span *ngIf="!showMore">{{ 'screens.help.releases.section.button.showmore' | translate }}</span>
            <span *ngIf="showMore">{{ 'screens.help.releases.section.button.showless' | translate }}</span>
        </span>
        <eui-icon-svg [icon]="showMore ? 'eui-chevron-up' : 'eui-chevron-down'" class="eui-u-ml-xs" size="s"></eui-icon-svg>
    </button>
</div>
