<div class="row align-items-center {{styleClass}}" [ngClass]="'rtpd-file'">
    <div class="col-md-10 d-flex align-items-center break-all">
        <em class="fa fa-file-text fa-lg ux-u-color-info"></em>
        {{file?.name}}
    </div>
    <div class="col-md-2 text-right" >
        <button euiButton euiRounded euiIconButton euiSizeM euiBasicButton euiDanger (click)="remove.emit()" title="{{ 'screens.common.remove.file.label' | translate }}">
            <span euiIcon iconClass="fa fa-trash-o" aria-label="Delete Icon" styleClass="eui-u-disabled"></span>
        </button>
    </div>
</div>

