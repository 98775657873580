import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasInputChanged, hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../../component/base.component';

@Component({
    selector: 'rtpd-driver-document',
    templateUrl: './document.component.html',
})
export class DriverDocumentComponent extends BaseComponent implements OnChanges {

    public documentTypes: EuiAutoCompleteItem[] = [];
    public worldCountries: EuiAutoCompleteItem[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (hasInputChanged(changes.isReadOnly)) {
            this.refDataLoaded = true;
        }
        if (hasRefDataChanged(changes.refData)) {
            this.documentTypes = this.refData.documentTypes;
            this.worldCountries = this.refData.worldCountries;
            reselectFormControlValue(this.driverDocumentType, this.documentTypes);
            reselectFormControlValue(this.driverDocumentIssuingCountry, this.worldCountries);
            this.refDataLoaded = true;
        }
    }

    public get driverDocumentType(): FormControl {
        return this.form.get('driverDocumentType') as FormControl;
    }

    public get driverDocumentNumber(): FormControl {
        return this.form.get('driverDocumentNumber') as FormControl;
    }

    public get driverDocumentIssueDate(): FormControl {
        return this.form.get('driverDocumentIssueDate') as FormControl;
    }

    public get driverDocumentExpirationDate(): FormControl {
        return this.form.get('driverDocumentExpirationDate') as FormControl;
    }

    public get driverDocumentIssuingCountry(): FormControl {
        return this.form.get('driverDocumentIssuingCountry') as FormControl;
    }
}
