<div *ngFor="let value of values; let i = index">
    <div class="rtpd-addon-input mb-2">
        <input euiInputText value="{{value?.label}}" (change)="onChange($event, i)" />
        <eui-icon role="button" euiDanger iconClass="eui-icon eui-icon-close-m" (click)="onItemRemoved(i)"></eui-icon>
    </div>
</div>
<button euiButton euiOutline euiPrimary class="rtpd-addon-button" (click)="onItemAdded()">
    <span euiIcon iconClass="eui-icon eui-icon-add"></span>
    <span euiLabel>{{ 'screens.declarations.button.add.vehicle' | translate }}</span>
</button>
