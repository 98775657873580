import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../component/base.component';

@Component({
    selector: 'rtpd-company-information',
    templateUrl: './company-information.component.html',
})
export class CompanyInformationComponent extends BaseComponent implements OnChanges {
    @Input() public formData: any;
    public postingCountries: EuiAutoCompleteItem[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (hasRefDataChanged(changes.refData)) {
            this.postingCountries = this.refData.postingCountries;
            reselectFormControlValue(this.operatorCountryOfRegistration, this.postingCountries);
            this.refDataLoaded = true;
        }
    }

    public get operatorId(): FormControl {
        return this.form.get('operatorId') as FormControl;
    }

    public get operatorName(): FormControl {
        return this.form.get('operatorName') as FormControl;
    }

    public get operatorEmail(): FormControl {
        return this.form.get('operatorEmail') as FormControl;
    }

    public get operatorLicenseNumber(): FormControl {
        return this.form.get('operatorLicenseNumber') as FormControl;
    }

    public get operatorRegisterNumber(): FormControl {
        return this.form.get('operatorRegisterNumber') as FormControl;
    }

    public get operatorVat(): FormControl {
        return this.form.get('operatorVat') as FormControl;
    }

    public get operatorCountryOfRegistration(): FormControl {
        return this.form.get('operatorCountryOfRegistration') as FormControl;
    }

    public validateNat() {
        this.form.controls.operatorRegisterNumber.updateValueAndValidity();
    }
}
