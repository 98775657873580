import { Injectable } from '@angular/core';
import { isEmpty } from './null-value.util';

@Injectable()
export class PathTranslationKeyResolver {

    public pathTranslationKeyMap: Map<string, string> = new Map<string, string>();

    public constructor() {
        this.pathTranslationKeyMap.set('/declarations/copy', 'paths.declarations.copy.declaration');
        this.pathTranslationKeyMap.set('/declarations/create', 'paths.declarations.create.declaration');
        this.pathTranslationKeyMap.set('/declarations/edit', 'paths.declarations.edit.declaration');
        this.pathTranslationKeyMap.set('/declarations/view', 'paths.declarations.view.declaration');
        this.pathTranslationKeyMap.set('/declarations/sendByEmail', 'paths.declarations.sendByEmail.declaration');
        this.pathTranslationKeyMap.set('/users/invite', 'paths.user.management.user.invitation');
        this.pathTranslationKeyMap.set('/users/view', 'paths.user.management.user.view');
        this.pathTranslationKeyMap.set('/users/edit', 'paths.user.management.user.edit');
        this.pathTranslationKeyMap.set('/drivers/add', 'paths.driver.management.driver.add');
        this.pathTranslationKeyMap.set('/drivers/edit', 'paths.driver.management.driver.edit');
        this.pathTranslationKeyMap.set('/drivers/view', 'paths.driver.management.driver.view');
        this.pathTranslationKeyMap.set('/drivers/upload', 'paths.driver.management.driver.upload');
        this.pathTranslationKeyMap.set('/account/select', 'paths.account.management.company.select');
        this.pathTranslationKeyMap.set('/account/create', 'paths.account.management.create');
        this.pathTranslationKeyMap.set('/account/view', 'paths.account.management.view');
        this.pathTranslationKeyMap.set('/account/edit', 'paths.account.management.edit');
        this.pathTranslationKeyMap.set('/requests/view', 'paths.requests.view.request');
        this.pathTranslationKeyMap.set('/declarations/bulk/submit', 'paths.declarations.bulk.submit');
        this.pathTranslationKeyMap.set('/declarations/bulk/edit', 'paths.declarations.bulk.edit');
    }

    public resolve(path: string): string {
        const template = Array.from(this.pathTranslationKeyMap.keys()).find(tmplt => path.includes(tmplt));
        const translationKey: string = this.pathTranslationKeyMap.get(template);
        if (!isEmpty(translationKey)) {
            return translationKey;
        } else {
            console.error('No translation key registered for path = ' + path);
        }
    }

}
