import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as accountActions from '../actions/account.actions';
import * as accountReducers from '../reducers/account.reducers';
import { Account } from '../models/account';
import { SearchFilters } from '../../declarations/models';

@Injectable({ providedIn: 'root' })
export class AccountDispatchers {

    public constructor(
        private store: Store<accountReducers.State>,
    ) {
    }

    public get(id: string) {
        this.store.dispatch(new accountActions.GetAccountAction(id));
    }

    public create() {
        this.store.dispatch(new accountActions.CreateAccountAction());
    }

    public cancel() {
        this.store.dispatch(new accountActions.CancelAccountAction());
    }

    public cancelUpdate() {
        this.store.dispatch(new accountActions.CancelUpdateAccountAction());
    }

    public valueChanged(account: Account) {
        this.store.dispatch(new accountActions.ChangeAccountAction(account));
    }

    public edit(id: string) {
        this.store.dispatch(new accountActions.EditAccountAction(id));
    }

    public update() {
        this.store.dispatch(new accountActions.UpdateAccountAction());
    }

    public refDataLoaded() {
        this.store.dispatch(new accountActions.RefDataLoadedAction());
    }

    public delete(id: string) {
        this.store.dispatch(new accountActions.DeleteAccountAction(id));
    }

    public searchDeclarations(filters: SearchFilters = undefined) {
        this.store.dispatch(new accountActions.SearchDeclarationsAction(filters));
    }
}
