export enum Status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    REMOVED = 'REMOVED',
}

export const StatusLabel = new Map<string, string>([
  [Status.ACTIVE, 'screens.user.management.users.registration.status.active.label'],
  [Status.INACTIVE, 'screens.user.management.users.registration.status.inactive.label'],
  [Status.REMOVED, 'screens.user.management.users.registration.status.removed.label'],
]);
