import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { addMonthsToDate } from '../utilities';

/**
 * This pipe returns a future date calculated from given date.
 */
@Injectable({ providedIn: 'root' })
@Pipe({ name: 'futureDate' })
export class FutureDatePipe implements PipeTransform {

    public transform(date: string, addMonths: number): Date {
        return date ? new Date(addMonthsToDate(date, addMonths)) : null;
    }
}
