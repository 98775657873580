<div class="notranslate">
    <eui-app>
        <eui-app-top-message *ngIf="isTraining" color="error" [isCloseable]="true">
            <h5 [innerHtml]= "'screens.landing.top.message.training.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-top-message *ngIf="showBanner" euiWarning [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.production.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-header id="500-header">
            <eui-header>
                <eui-header-logo></eui-header-logo>
                <eui-header-environment *ngIf="nonProduction">{{ envName }}</eui-header-environment>
                <eui-header-app>
                    <eui-header-app-name
                        class="appname">{{ 'application.title.full' | translate }}</eui-header-app-name>
                </eui-header-app>
            </eui-header>
        </eui-app-header>

        <eui-app-toolbar id="500-toolbar">
            <eui-toolbar>
                <eui-toolbar-logo></eui-toolbar-logo>
            </eui-toolbar>
        </eui-app-toolbar>

        <eui-app-page-wrapper id="500-wrapper" class="no-flex">
            <eui-page-header label="{{ 'screens.500.title' | translate }}">
            </eui-page-header>
            <div class="eui-page-header" ></div>
            <eui-block-content>
                <p>{{ 'screens.500.message.1.label' | translate }}</p>
                <p>{{ 'screens.500.message.2.label' | translate }}</p>
                <span>
                          <a class="mr-2 m-lg-2" euiButton euiSizeS euiBasicButton euiOutline euiPrimary role="button" href="/">
                            {{ 'screens.500.reload.label' | translate }}
                          </a>&nbsp;
                          <button *ngIf="authenticated" class="mr-2 m-lg-2" euiButton euiSizeS euiBasicButton euiOutline euiPrimary (click)="signOut()">
                              <span euiLabel>{{'screens.500.sign.out.label' | translate}}</span>
                          </button>
                </span>
            </eui-block-content>
        </eui-app-page-wrapper>

        <eui-app-footer id="500-footer" >
            <eui-footer>
                <div class="col-12 text-center">
                    <div class="flex-container justify-content-around">
                        <span>
                            <span *ngIf="appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong> - {{ appReleaseDate }}</span>
                            <span *ngIf="!appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong></span>
                            <span>
                                |
                                <a *ngIf="privacyStatementLink.url"
                                   target="_blank" rel="noopener"
                                   href="{{ privacyStatementLink.url }}">{{ privacyStatementLink.label | translate }}</a>
                            </span>
                        </span>
                    </div>
                </div>
            </eui-footer>
        </eui-app-footer>
    </eui-app>
</div>
