import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CachePreventionInterceptor, CorsSecurityInterceptor } from '@eui/core';

import { AWSAuthInterceptor } from './aws-auth.interceptor';
import { CacheInterceptor } from './cache.interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CorsSecurityInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CachePreventionInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AWSAuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
];
