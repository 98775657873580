import { Inject, Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CONFIG_TOKEN, UxAppShellService } from '@eui/core';

import { AccountRefDataService } from './account-refdata.service';
import { GrowlHelper } from '../../../core/services/growl-helper.service';

@Injectable({ providedIn: 'root' })
export class AccountRefDataResolver implements Resolve<Observable<any>> {

  public constructor(
        @Inject(CONFIG_TOKEN) private config,
        private router: Router,
        private uxAppShellService: UxAppShellService,
        private growlHelper: GrowlHelper,
        private accountRefData: AccountRefDataService,
  ) {
  }

  public resolve(): Observable<any> {
    this.uxAppShellService.isBlockDocumentActive = true;
    return this.accountRefData.refData$.pipe(
      finalize(() => {
        this.uxAppShellService.isBlockDocumentActive = false;
      }),
      catchError(error => {
        this.growlHelper.growlError(`RefData remote service error: ${error.message || JSON.stringify(error)}`);
        this.router.navigate(['/']);
        return throwError(() => error);
      }),
    );
  }
}
