import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionStorageService } from '@eui/core';
import { AWSAuthService } from '@rtpd/core';
import { GrowlHelper } from '../services/growl-helper.service';

@Injectable({ providedIn: 'root' })
export class AWSAuthGuard implements CanActivate {
    private readonly storage_key = 'imi-registration-redirect';

    public constructor(
        private router: Router,
        private growlHelper: GrowlHelper,
        private authService: AWSAuthService,
        private storageService: SessionStorageService,
    ) {}

    public canActivate(_, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this.authService.isEnabled() && !this.authService.isAuthenticated()) {

            if (!this.authService.isAuthCallback(state.url) && this.isRegistrationUrl(state.url)) {
                this.setRegistrationUrl(state.url);
            }

            return this.authService.signIn().pipe(
                map(() => {
                    if (this.authService.isAuthCallback(state.url)) {
                        let url = this.popRegistrationUrl();
                        if (!url) {
                            url = this.authService.removeAuthCallback(state.url);
                        }
                        return this.router.parseUrl(url);
                    }
                    return true;
                }),
                catchError(error => {
                    const navigation = this.router.getCurrentNavigation();
                    this.accessDenied(error, navigation?.extras.state?.reload);
                    return of(false);
                }),
            );
        } else {
            return of(true);
        }
    }

    private isRegistrationUrl(url: string): boolean {
        return ['/registration', '/account/create'].some(path => url?.includes(path));
    }

    private setRegistrationUrl(url: string) {
        this.storageService.set(this.storage_key, url);
    }

    private popRegistrationUrl(): string | undefined {
        const url = this.storageService.get(this.storage_key);
        this.storageService.remove(this.storage_key);
        return url;
    }

    private accessDenied(error: any, reload: boolean) {
        this.growlHelper.growlError(`Access is denied: ${error && (error.message || JSON.stringify(error))}`);
        console.error(`Access is denied: ${error && (error.message || JSON.stringify(error))}`);
        if (reload) {
            this.router.navigate(['403'], { state: { reload: true }, queryParams: { reload: true } });
        } else {
            this.router.navigate(['403']);
        }
    }
}
