import { NgModule } from '@angular/core';
import {
    CountryFlagPipe, CountryNamePipe, DocumentTypePipe,
    FutureDatePipe, GreaterThanPipe, RefDataPipe, RtpdCurrencyPipe,
    AuthorityNamePipe, MessageTypeKeyPipe, ChipsPositionPipe, MessageSubTypeKeyPipe,
} from '@rtpd/shared';

export const PIPES = [
    CountryFlagPipe,
    CountryNamePipe,
    DocumentTypePipe,
    GreaterThanPipe,
    FutureDatePipe,
    RefDataPipe,
    RtpdCurrencyPipe,
    AuthorityNamePipe,
    MessageTypeKeyPipe,
    MessageSubTypeKeyPipe,
    ChipsPositionPipe,
];

@NgModule({
    declarations: PIPES,
    exports: PIPES,
})
export class PipesModule {
}
