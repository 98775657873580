<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.contract.contractStart.label' | translate }}" [isRequired]="!isReadOnly">
            <rtpd-show-date-or-datepicker
                [isReadOnly]="isReadOnly"
                [form]="form"
                [control]="driverContractStartDate"
                [dateValue]="driverContractStartDate.value"></rtpd-show-date-or-datepicker>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverContractStartDate.touched">
            <ux-control-feedback *ngIf="driverContractStartDate.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.contract.contractStart.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.contract.applicableLaw.label' | translate }}"
                       infoLabel="{{ isReadOnly ? '' : 'screens.driver.management.driver.contract.applicableLaw.infoLabel' | translate }}" [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="driverApplicableLaw" [autocompleteData]="worldCountries" [itemsSortOrder]="'ASC'">
                placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverApplicableLaw.touched">
            <ux-control-feedback *ngIf="driverApplicableLaw.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.contract.applicableLaw.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
