import { UserDetails } from './user-details';

import { map } from 'rxjs/operators';
import { Role } from '../role';
import { UsersSearchResult } from '../../../features/users/models/users/users-search-result.model';
import { OperatorDetails } from '../operators/operator-details';

export function convertUsers() {
    return map((userData: UsersSearchResult) => {
        if (userData.items) {
            return new UsersSearchResult({ items: userData.items.map(user => convertUserData(user)) });
        } else {
            return new UsersSearchResult({ items: [] });
        }
    });
}

export function convertUser() {
    return map((user: User) => {
        return convertUserData(user);
    });
}

export function convertUserData(userData: any): User {
    return {
        ...userData,
        userId: userData.id,
    };
}

export function mapToRequestBody(user: User): any {
    const userRequestBody = { ...user };
    delete userRequestBody.userId;
    return userRequestBody;
}

export class User extends UserDetails {

    public registered?: boolean;
    public sub?: string;
    public token?: string;
    public role: Role;
    public country: string;
    public regionId?: number;
    public operatorDetails: OperatorDetails[];

    public constructor(values: Object = {}) {
        super(values);
        Object.assign(this, values);
    }
}
