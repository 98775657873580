import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Output,
} from '@angular/core';

@Directive({
    selector: '[rtpdDragNDropFileUpload]',
})
export class DragNDropFileUploadDirective {
    @Output() public fileDropped = new EventEmitter<any>();

    @HostBinding('style.background-color') private background = '#ffffff';

    @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#e2eefd';
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#ffffff';
    }

    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = '#ffffff';
        const files = evt.dataTransfer?.files;
        if (files && files.length) {
            this.fileDropped.emit(files);
        }
    }
}
