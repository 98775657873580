export function nvl(...params): any {
  return params.find(param => param != null);
}

export function isEmpty(value: any, allowEmptyString = false): boolean {
  return (value === null)
      || (value === undefined)
      || (!allowEmptyString ? value === '' : false)
      || (Array.isArray(value) && (value || []).filter(item => !isEmpty(item)).length == 0)
      || (typeof value === 'object' && Object.keys(value).length == 0);
}

