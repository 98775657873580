import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import {
    CoreModule as EuiCoreModule,
    CoreModuleEffects,
    EUI_CONFIG_TOKEN,
    translateConfig,
    CoreModule as UxCoreModule,
    SessionStorageService,
} from '@eui/core';

import { appConfig } from '../../config';
import { environment } from '../../environments/environment';
import { httpInterceptorProviders } from './interceptors';

import { metaReducers, REDUCER_TOKEN } from './reducers/core.reducers';
import { CoreEffects } from './effects';
import { PathTranslationKeyResolver } from './helpers';
import { SharedModule } from '../shared/shared.module';
import { PathSelectCompanyVerifier } from './helpers/path-select-company.verifier';
import { PathRegistrationVerifier } from './helpers/path-registration-verifier.service';
import { Any } from './helpers/any.marker';
import { PathRegistrationProcessVerifier } from './helpers/path-registration-process-verifier.service';
import { GrowlHelper } from './services/growl-helper.service';
import { ApiErrorHelper } from './helpers/api-error.helper';
import { HttpClientModule } from '@angular/common/http';
import { BreadcrumbService } from './services/breadcrumb.service';

@NgModule({
    imports: [
        SharedModule,
        HttpClientModule,
        UxCoreModule.forRoot(),
        EuiCoreModule.forRoot(),
        EffectsModule.forRoot([...CoreModuleEffects, CoreEffects]),
        TranslateModule.forRoot(translateConfig),
        StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }),
        !environment.production
            ? StoreDevtoolsModule.instrument({ maxAge: 50 })
            : [],
    ],
    providers: [
        {
            provide: EUI_CONFIG_TOKEN,
            useValue: { appConfig, environment },
        },
        ...httpInterceptorProviders,
        SessionStorageService,
        PathTranslationKeyResolver,
        Any,
        PathSelectCompanyVerifier,
        PathRegistrationVerifier,
        PathRegistrationProcessVerifier,
        GrowlHelper,
        ApiErrorHelper,
        BreadcrumbService,
    ],
})
export class CoreModule {
    public constructor() {
        registerLocaleData(localeEn);
    }
}
