import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { isEmpty } from '../../core/helpers/null-value.util';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'rtpdCurrency' })
export class RtpdCurrencyPipe implements PipeTransform {
    private _currency = new CurrencyPipe('fr');

    public transform(value: number, display: 'code' | 'symbol' | string = 'code', digitsInfo?: string): string {
      if (isEmpty(value) || isNaN(value)) {
        value = 0;
      }

      if (value % 1 === 0) {
        return this._currency.transform(value, 'EUR', display, digitsInfo || '1.0-2');
      } else {
        return this._currency.transform(value, 'EUR', display, digitsInfo || '1.2-2');
      }
    }
}
