import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'rtpd-file',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent {
    @Input() public file: File;
    @Input() public styleClass = '';

    @Output() public remove = new EventEmitter<void>();
}
