<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.document.type.label' | translate }}" [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="driverDocumentType" [autocompleteData]="documentTypes" [itemsSortOrder]="'ASC'">
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverDocumentType.hasError('requiredValidDocumentType')">
            <ux-control-feedback *ngIf="driverDocumentType.hasError('requiredValidDocumentType')" typeClass="danger">
                {{ 'screens.driver.management.driver.document.type.validation.error.requiredValidDocumentType' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="driverDocumentType.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.document.type.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.document.number.label' | translate }}" [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="driverDocumentNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}"
                placeholderLabel="{{ 'screens.driver.management.driver.document.number.label' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverDocumentNumber.touched">
            <ux-control-feedback *ngIf="driverDocumentNumber.hasError('required')" typeClass="danger">
                {{ 'screens.driver.management.driver.document.number.validation.error.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.document.issueDate.label' | translate }}">
            <rtpd-show-date-or-datepicker
                [isReadOnly]="isReadOnly"
                [form]="form"
                [control]="driverDocumentIssueDate"
                [dateValue]="driverDocumentIssueDate.value"></rtpd-show-date-or-datepicker>
        </ux-form-group>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.document.expirationDate.label' | translate }}">
            <rtpd-show-date-or-datepicker
                [isReadOnly]="isReadOnly"
                [form]="form"
                [control]="driverDocumentExpirationDate"
                [dateValue]="driverDocumentExpirationDate.value"></rtpd-show-date-or-datepicker>
        </ux-form-group>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.driver.management.driver.document.issuingCountry.label' | translate }}" [isRequired]="!isReadOnly">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="driverDocumentIssuingCountry" [autocompleteData]="worldCountries" [itemsSortOrder]="'ASC'"
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-error-displayed col-md-8 offset-md-4" *ngIf="showErrors || driverDocumentIssuingCountry.touched">
            <ux-control-feedback *ngIf="driverDocumentIssuingCountry.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
