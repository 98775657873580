import { Inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EUI_CONFIG_TOKEN } from '@eui/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'authorityName', pure: false })
export class AuthorityNamePipe implements PipeTransform {
    public constructor(
        private translateService: TranslateService,
        @Inject(EUI_CONFIG_TOKEN) private euConfig,
    ) {
    }

    public transform(authorityId: string, authorityName: string): string {
        const translationKey = 'screens.common.bo.authorities.' + this.euConfig.environment.name + `.${authorityId}`;
        let boAuthorityNameTranslation = this.translateService.instant(translationKey);

        if (boAuthorityNameTranslation === translationKey || boAuthorityNameTranslation === null) {
            boAuthorityNameTranslation = authorityName;
        }

        if (boAuthorityNameTranslation === null) {
            boAuthorityNameTranslation = this.translateService.instant('screens.requests.management.competent-authority');
        }

        return boAuthorityNameTranslation;
    }
}
