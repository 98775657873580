import { TranslateService } from '@ngx-translate/core';
import { formatAsAutoCompleteItem } from '@rtpd/core';
import { map } from 'rxjs/operators';
import { formatDate } from '@rtpd/shared';

export function mapDriverToFormData(translateService: TranslateService): any {
    return map((driver: any) => {
        return {
            driverId: driver.driverId ? {
                id: driver.driverId,
                label: [
                    driver.driverLatinFirstName,
                    driver.driverLatinLastName,
                    driver.driverDateOfBirth ? '(' + formatDate(driver.driverDateOfBirth) + ')' : '',
                ].join(' ').trim(),
            } : undefined,
            driverLatinFirstName: driver.driverLatinFirstName,
            driverLatinLastName: driver.driverLatinLastName,
            driverLatinFullName: (driver.driverLatinFirstName || driver.driverLatinLastName) ?
                [driver.driverLatinFirstName, driver.driverLatinLastName].join(' ').trim() :
                undefined,
            driverNonLatinFirstName: driver.nonLatinFirstName,
            driverNonLatinLastName: driver.nonLatinLastName,
            driverNonLatinFullName: (driver.nonLatinFirstName || driver.nonLatinLastName) ?
                [driver.nonLatinFirstName, driver.nonLatinLastName].join(' ').trim() :
                undefined,
            driverDateOfBirth: driver.driverDateOfBirth,
            driverEmail: driver.driverEmail,
            driverLicenseNumber: driver.driverLicenseNumber,
            driverDocumentType: driver.driverDocumentType ?
                formatAsAutoCompleteItem(driver.driverDocumentType, 'screens.common.document.type.', translateService) :
                undefined,
            driverDocumentNumber: driver.driverDocumentNumber,
            driverDocumentIssueDate: driver.driverDocumentIssueDate,
            driverDocumentExpirationDate: driver.driverDocumentExpirationDate,
            driverAddressStreet: driver.driverAddressStreet,
            driverAddressPostCode: driver.driverAddressPostCode,
            driverAddressCity: driver.driverAddressCity,
            driverAddressCountry: driver.driverAddressCountry ?
                formatAsAutoCompleteItem(driver.driverAddressCountry, 'screens.common.countries.', translateService) :
                undefined,
            driverContractStartDate: driver.driverContractStartDate,
            driverApplicableLaw: driver.driverApplicableLaw ?
                formatAsAutoCompleteItem(driver.driverApplicableLaw, 'screens.common.countries.', translateService) :
                undefined,
            driverDocumentIssuingCountry: driver.driverDocumentIssuingCountry ?
                formatAsAutoCompleteItem(driver.driverDocumentIssuingCountry, 'screens.common.countries.', translateService) :
                undefined,
            driverCardNumber:driver.driverCardNumber,
        } as DeclarationDriver;
    });
}

export interface DeclarationDriver {
    driverId: any;
    driverLatinFirstName?: string;
    driverLatinLastName?: string;
    driverLatinFullName?: string;
    driverNonLatinFirstName?: string;
    driverNonLatinLastName?: string;
    driverNonLatinFullName?: string;
    driverEmail?: string;
    driverDateOfBirth?: string;
    driverLicenseNumber?: string;
    driverDocumentType?: any;
    driverDocumentNumber?: string;
    driverDocumentIssueDate?: string;
    driverDocumentExpirationDate?: string;
    driverAddressStreet?: string;
    driverAddressPostCode?: string;
    driverAddressCity?: string;
    driverAddressCountry?: any;
    driverContractStartDate?: string;
    driverApplicableLaw?: any;
    driverDocumentIssuingCountry: any;
    driverCardNumber: string;
}

