import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { AppShellComponent } from './core/app-shell/app-shell.component';
import { RtpdLandingComponent } from './core/components/landing/landing.component';
import { RtpdHelpComponent } from './core/components/help/help.component';
import { Page500Component } from './core/components/error-pages/page-500/page-500.component';
import { Page404Component } from './core/components/error-pages/page-404/page-404.component';
import { Page403Component } from './core/components/error-pages/page-403/page-403.component';

import { Permission } from '@rtpd/shared';
import { CoreRefDataResolver, PermissionAuthGuard } from '@rtpd/core';
import { AWSAuthGuard } from './core/guards/aws-auth.guard';
import { AccountRefDataResolver } from './features/account/services';

const featureRoutes: Route[] = [
    {
        path: '',
        resolve: { refData: CoreRefDataResolver },
        component: AppShellComponent,
        canActivate: [AWSAuthGuard],
        children: [
            {
                path: 'home',
                canActivate: [PermissionAuthGuard],
                data: {
                    permissions: [
                        Permission.ViewDeclarations,
                        Permission.ViewDrivers,
                        Permission.ViewRequests,
                        Permission.ViewUsers,
                    ],
                },
                loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'account',
                resolve: { refData: AccountRefDataResolver },
                loadChildren: () => import('./features/account/account.module').then(m => m.AccountModule),
            },
            {
                path: 'declarations',
                // resolve: { refData: DeclarationsRefDataResolver },
                canActivate: [PermissionAuthGuard],
                data: { permissions: [Permission.ViewDeclarations] },
                loadChildren: () => import('./features/declarations/declarations.module').then(m => m.DeclarationModule),
            },
            {
                path: 'drivers',
                canActivate: [PermissionAuthGuard],
                data: { permissions: [Permission.ViewDrivers] },
                loadChildren: () => import('./features/drivers/drivers.module').then(m => m.DriverModule),
            },
            {
                path: 'requests',
                // resolve: { refData: RequestsRefDataResolver },
                canActivate: [PermissionAuthGuard],
                data: { permissions: [Permission.ViewRequests] },
                loadChildren: () => import('./features/requests/requests.module').then(m => m.RequestModule),
            },
            {
                path: 'users',
                canActivate: [PermissionAuthGuard],
                data: { permissions: [Permission.ViewUsers] },
                loadChildren: () => import('./features/users/users.module').then(m => m.UserModule),
            },
            {
                path: 'apikey',
                canActivate: [PermissionAuthGuard],
                data: { permissions: [Permission.ViewApiKey] },
                loadChildren: () => import('./features/apikey/apikey.module').then(m => m.ApiKeyModule),
            },
            // {
            //     path: 'help',
            //     // resolve: { refData: HelpRefDataResolver },
            //     canActivate: [PermissionAuthGuard],
            //     data: { permissions: [Permission.ViewHelp] },
            //     // loadChildren: () => import('./features/help/help.module').then(m => m.Module)
            // },
            {
                path: 'registration',
                loadChildren: () => import('./features/users/registration.module').then(m => m.RegistrationModule),
            },
        ],
    },
];

const routes: Routes = [
    { path: '', redirectTo: 'landing', pathMatch: 'full' },
    { path: 'index.html', redirectTo: 'landing', pathMatch: 'full' },
    {
        path: '500',
        component: Page500Component,
    },
    {
        path: '404',
        component: Page404Component,
    },
    {
        path: '403',
        component: Page403Component,
    },
    {
        path: 'landing',
        component: RtpdLandingComponent,
    },
    {
        path: 'help',
        component: RtpdHelpComponent,
    },
    {
        path: 'scan',
        loadChildren: () =>
            import('./features/scan/scan.module').then((m) => m.ScanModule),
    },
    ...featureRoutes,
    {
        path: '**',
        redirectTo: '/404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
