import {
    ChangeDetectionStrategy,
    Component,
    Input, OnChanges, OnInit, SimpleChanges,
} from '@angular/core';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { FormGroup } from '@angular/forms';
import { hasInputChanged } from '@rtpd/shared';
import { isEmpty } from '../../../core/helpers';

@Component({
    selector: 'rtpd-multiplier-text',
    templateUrl: './multiplier-text.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiplierTextComponent implements OnInit, OnChanges {
    @Input() public form: FormGroup;
    @Input() public controlName: string;
    @Input() public values: EuiAutoCompleteItem[];
    @Input() public isRequired = false;
    @Input() public isValuesSeparated = false;
    @Input() public splitSeparator = /(?:,|;)+/; // separator , or ;
    @Input() public isUpperCase = false;

    public ngOnInit() {
        if (isEmpty(this.values)) {
            this.values = [new EuiAutoCompleteItem({ id: null, label: null })];
        }
        this.setErrors();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (hasInputChanged(changes.values)) {
            this.values = this.values.map(val => new EuiAutoCompleteItem(val));
        }
        this.setErrors();
    }

    public onItemAdded() {
        if (!this.values.some(val => val.id === null)) {
            this.values = this.values.concat(new EuiAutoCompleteItem({ id: null, label: null }));
        }
        this.setErrors();
    }

    public onChange(event: any, i: number) {
        const newValue = event.target.value.trim();
        this.values = this.splitNewValuesOnChange(newValue, i, this.values);
        this.form.patchValue({ [this.controlName]: this.values });
        this.setErrors();
    }

    public onItemRemoved(i: number) {
        const isOneEmptyElement =  this.getOneEmptyElement();
        if (!isOneEmptyElement) {
            this.values = this.values.filter((val, idx) => i != idx);
            if (isEmpty(this.values)) {
                this.values = [new EuiAutoCompleteItem({ id: null, label: null })];
            }
        } else {
            this.values = [];
        }
        const value = { [this.controlName]: this.values };
        this.form.patchValue(value);
        this.setErrors();
    }

    public setErrors() {
        const isOneEmptyElement = this.getOneEmptyElement();
        const hasErrors = this.isRequired && isOneEmptyElement;
        if (hasErrors) {
            this.form.controls[this.controlName].setErrors({ required: true });
        } else {
            this.form.controls[this.controlName].setErrors(null);
        }
    }

    private getOneEmptyElement() {
        return this.values.length == 0 || (this.values.length == 1 && this.values[0].id == null);
    }

    private splitNewValuesOnChange(newValue: string, i: number, existingValuesArray: EuiAutoCompleteItem[]): Array<EuiAutoCompleteItem> {
        // if required; split values using separator, trim and filter empty
        let newValueArray: Array<string>;
        if (this.isValuesSeparated) {
            newValueArray = newValue.split(this.splitSeparator).map(val => val.trim()).filter(String);
        } else {
            newValueArray = [newValue];
        }
        // uppercase
        if (this.isUpperCase) {
            newValueArray = newValueArray.map(val => val.toUpperCase());
        }
        // reconstruct array of EuiAutoCompleteItem values
        const before = existingValuesArray.slice(0, i);
        const newValueItemArray = newValueArray.map(val => new EuiAutoCompleteItem({ id: val, label: val }));
        const after = existingValuesArray.slice(i + 1);
        return [...before, ...newValueItemArray, ...after];
    }
}
