import { Permission } from './permission';

export enum Role {
    /*
    * Users with this role can manage the operator account and the users
    */
    OPERATOR_ACCOUNT_ADMINISTRATOR = 'OperatorAccountAdministrator',
    /*
     * Users with this role can manage declarations and requests for documents
     */
    DECLARATION_MANAGER = 'DeclarationManager',
    /*
     * Users with this role can manage the list of drivers
     */
    REFERENCE_DATA_MANAGER = 'ReferenceDataManager',
}

export const RoleLabel = new Map<string, string>([
  [Role.OPERATOR_ACCOUNT_ADMINISTRATOR, 'Operator Account Administrator'],
]);

export const RolePermissions = new Map<Role, Permission[]>([
  [Role.OPERATOR_ACCOUNT_ADMINISTRATOR, [
    Permission.ViewHome,
    Permission.ViewDeclarations,
    Permission.ManageDeclarations,
    Permission.ViewDrivers,
    Permission.ManageDrivers,
    Permission.ViewRequests,
    Permission.ManageRequests,
    Permission.ViewAccount,
    Permission.ViewApiKey,
    Permission.ManageAccount,
    Permission.ViewUsers,
    Permission.ViewUsers,
    Permission.ManageUsers,
    Permission.ViewSystemSettings,
    Permission.ViewHelp,
  ]],
]);
