<strong *ngIf="isReadOnly">{{dateValue | date: dateFormat}}</strong>
<div *ngIf="!isReadOnly" [formGroup]="form">
    <ux-datepicker
        [formControl]="control"
        dateOutputFormat="YYYY-MM-DD"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [isReadOnly]="isReadOnly">
    </ux-datepicker>
</div>


