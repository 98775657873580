import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../component/base.component';

@Component({
  selector: 'rtpd-company-address',
  templateUrl: './company-address.component.html',
})
export class CompanyAddressComponent extends BaseComponent implements OnChanges {
  public postingCountries: EuiAutoCompleteItem[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (hasRefDataChanged(changes.refData)) {
      this.postingCountries = this.refData.postingCountries;
      reselectFormControlValue(this.operatorAddressCountry, this.postingCountries);
      this.refDataLoaded = true;
    }
  }

  public get operatorAddressStreet(): FormControl {
    return this.form.get('operatorAddressStreet') as FormControl;
  }

  public get operatorAddressPostCode(): FormControl {
    return this.form.get('operatorAddressPostCode') as FormControl;
  }

  public get operatorAddressCity(): FormControl {
    return this.form.get('operatorAddressCity') as FormControl;
  }

  public get operatorAddressCountry(): FormControl {
    return this.form.get('operatorAddressCountry') as FormControl;
  }
}
