import { Injectable, Pipe, PipeTransform } from '@angular/core';

export type ChipsPosition = 'top' | 'bottom' | 'inside';
export const CHIPS_POSITION_INSIDE: ChipsPosition = 'inside';
export const CHIPS_POSITION_BOTTOM: ChipsPosition = 'bottom';
export const CHIPS_POSITION_TOP: ChipsPosition = 'top';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'chipsPosition' })
export class ChipsPositionPipe implements PipeTransform {

    public transform(isReadOnly: boolean): ChipsPosition {
        if (isReadOnly) {
            return CHIPS_POSITION_INSIDE;
        } else {
            return CHIPS_POSITION_TOP;
        }
    }
}
