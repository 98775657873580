import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'messageSubTypeKey' })
export class MessageSubTypeKeyPipe implements PipeTransform {
    public transform(messageType: string, messageTypes: Object, keySubType: string): string {
        let key = '';

        if (messageTypes && messageType && messageTypes[`${messageType}`].subTypes && keySubType) {
            key = messageTypes[`${messageType}`].subTypes[`${keySubType}`];
        }

        return key;
    }
}
