<div class="notranslate">
    <eui-app id="help-container">
        <!-- Top Messages -->
        <eui-app-top-message *ngIf="isTraining" color="error" [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.training.notification' | translate"></h5>
        </eui-app-top-message>
        <eui-app-top-message *ngIf="showBanner" euiWarning [isCloseable]="true">
            <h5 [innerHtml]="'screens.landing.top.message.production.notification' | translate"></h5>
        </eui-app-top-message>

        <!-- Header -->
        <eui-app-header id="landing-header">
            <eui-header>
                <eui-header-logo></eui-header-logo>
                <eui-header-environment *ngIf="nonProduction">{{ envName }}</eui-header-environment>
                <eui-header-app>
                    <eui-header-app-name class="appname">{{ 'application.title.full' | translate }}</eui-header-app-name>
                </eui-header-app>
            </eui-header>
        </eui-app-header>

        <!-- Toolbar -->
        <eui-app-toolbar id="landing-toolbar">
            <eui-toolbar>
                <eui-toolbar-logo></eui-toolbar-logo>
                <eui-toolbar-items euiPositionRight>
                    <rtpd-page-search (search)="searchText = $event"></rtpd-page-search>
                </eui-toolbar-items>
            </eui-toolbar>
        </eui-app-toolbar>

        <!-- Page Content -->
        <eui-app-page-wrapper id="help-container" class="no-flex">

            <div class="page-title">
                <h1>{{ 'screens.help.page.title' | translate }}</h1>
            </div>

            <div class="section">
                <h3 class="section-title">{{ 'screens.help.sections.title' | translate | uppercase}}</h3>
                <div class="section-content">
                    <ul euiList>
                        <li euiListItem>
                            <a class="text-wrap col-12" href="javascript:void(0)" (click)="navToSection('tutorials')">
                                {{ 'screens.help.user.guide.section.title'  | translate }}
                            </a>
                        </li>
                        <li euiListItem>
                            <a class="text-wrap col-12" href="javascript:void(0)" (click)="navToSection('glossary')">
                                {{ 'screens.help.glossary.section.title'  | translate }}
                            </a>
                        </li>
                        <li euiListItem>
                            <a class="text-wrap col-12" href="javascript:void(0)" (click)="navToSection('faq')">
                                {{ 'screens.help.faq.section.title'  | translate }}
                            </a>
                        </li>
                        <li euiListItem *ngIf="showReleasesSection">
                            <a class="text-wrap col-12" href="javascript:void(0)" (click)="navToSection('releases')">
                                {{ 'screens.help.releases.section.title'  | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div id="tutorials" class="section">
                <h3 class="section-title">{{ 'screens.help.user.guide.section.title' | translate | uppercase}}</h3>
                <div class="section-content">
                    <ul euiList>
                        <a class="text-wrap col-12" target="_blank" rel="noopener"
                           href="{{ 'screens.help.user.guide.section.training.materials.link' | translate }}" euiListItem>
                            {{ 'screens.help.user.guide.section.training.materials.link.text'  | translate }}
                        </a>
                    </ul>
                    <eui-card euiCollapsible [euiCollapsed]="true" [rtpdCardSearchCollapse]="searchText" class="eui-t-card-compact">
                        <eui-card-header hasHeaderClickToggle="true">
                            <eui-card-header-title>
                                {{ 'screens.help.user.guide.section.training.videos.title' | translate }}
                            </eui-card-header-title>
                        </eui-card-header>
                        <eui-card-content>
                            <ul>
                                <li euiList>
                                    <h5>{{ 'screens.help.user.guide.section.training.videos.register.group' | translate }}</h5>
                                    <ul euiList>
                                        <li euiListItem>
                                            <a class="text-wrap col-12" target="_blank" rel="noopener"
                                               href="{{ 'screens.help.user.guide.section.training.videos.register.link.0' | translate }}" euiListItem>
                                               {{ 'screens.help.user.guide.section.training.videos.register.link.text.0'  | translate }}
                                            </a>
                                        </li>
                                        <li euiListItem>
                                            <a class="text-wrap col-12" target="_blank" rel="noopener"
                                               href="{{ 'screens.help.user.guide.section.training.videos.register.link.1' | translate }}" euiListItem>
                                               {{ 'screens.help.user.guide.section.training.videos.register.link.text.1'  | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                    <h5>{{ 'screens.help.user.guide.section.training.videos.invitation.group' | translate }}</h5>
                                    <ul euiList>
                                        <li euiListItem>
                                            <a class="text-wrap col-12" target="_blank" rel="noopener"
                                               href="{{ 'screens.help.user.guide.section.training.videos.invitation.link.0' | translate }}" euiListItem>
                                               {{ 'screens.help.user.guide.section.training.videos.invitation.link.text.0'  | translate }}
                                            </a>
                                        </li>
                                        <li euiListItem>
                                            <a class="text-wrap col-12" target="_blank" rel="noopener"
                                               href="{{ 'screens.help.user.guide.section.training.videos.invitation.link.1' | translate }}" euiListItem>
                                               {{ 'screens.help.user.guide.section.training.videos.invitation.link.text.1'  | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h5>{{ 'screens.help.user.guide.section.training.videos.access.account.group' | translate }}</h5>
                            <ul>
                                <li euiList>
                                    <ul euiList>
                                        <li euiListItem>
                                            <a class="text-wrap col-12" target="_blank" rel="noopener"
                                               href="{{ 'screens.help.user.guide.section.training.videos.access.account.link.0' | translate }}" euiListItem>
                                                {{ 'screens.help.user.guide.section.training.videos.access.account.link.text.0'  | translate }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h5>{{ 'screens.help.user.guide.section.training.videos.how.to.group' | translate }}</h5>
                            <ul>
                                <li euiList>
                                <ul euiList>
                                    <li euiListItem>
                                        <a class="text-wrap col-12" target="_blank" rel="noopener"
                                           href="{{ 'screens.help.user.guide.section.training.videos.how.to.link.0' | translate }}" euiListItem>
                                           {{ 'screens.help.user.guide.section.training.videos.how.to.link.text.0'  | translate }}
                                        </a>
                                    </li>
                                    <li euiListItem>
                                        <a class="text-wrap col-12" target="_blank" rel="noopener"
                                           href="{{ 'screens.help.user.guide.section.training.videos.how.to.link.1' | translate }}" euiListItem>
                                           {{ 'screens.help.user.guide.section.training.videos.how.to.link.text.1'  | translate }}
                                        </a>
                                    </li>
                                    <li euiListItem>
                                        <a class="text-wrap col-12" target="_blank" rel="noopener"
                                           href="{{ 'screens.help.user.guide.section.training.videos.how.to.link.2' | translate }}" euiListItem>
                                            {{ 'screens.help.user.guide.section.training.videos.how.to.link.text.2'  | translate }}
                                        </a>
                                    </li>
                                    <li euiListItem>
                                        <a class="text-wrap col-12" target="_blank" rel="noopener"
                                           href="{{ 'screens.help.user.guide.section.training.videos.how.to.link.3' | translate }}" euiListItem>
                                            {{ 'screens.help.user.guide.section.training.videos.how.to.link.text.3'  | translate }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            </ul>
                        </eui-card-content>
                    </eui-card>
                </div>
            </div>

            <div id="glossary" class="section">
                <h3 class="section-title">
                    {{ 'screens.help.glossary.section.title' | translate }}
                </h3>
                <div class="section-content">
                    <eui-card euiCollapsible [euiCollapsed]="true" [rtpdCardSearchCollapse]="searchText" class="eui-t-card-compact">
                        <eui-card-header hasHeaderClickToggle="true">
                            <eui-card-header-title euiTooltip="{{ 'screens.help.glossary.section.text' | translate }}">
                                <p class="text-wrap col-12" [innerHtml]="'screens.help.glossary.section.text' | translate "></p>
                            </eui-card-header-title>
                        </eui-card-header>
                        <eui-card-content>
                            <div class="container">
                                <div class="row align-items-start">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <h5 class="glossary-title">{{ glossaryLabel.postingInformation | translate }}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.postingCountry }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.postingCountry | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.periodOfPosting }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.periodOfPosting | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.typeOfOperation }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.typeOfOperation | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.typeOfCarriage }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.typeOfCarriage | translate }}</span>
                                        </p>

                                        <h5 class="glossary-title">{{ glossaryLabel.declarationDetails | translate}}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.declarationNumber | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.declarationNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.lastUpdate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.lastUpdate | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.submissionDate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.submissionDate | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.withdrawalDate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.withdrawalDate | translate }}</span>
                                        </p>

                                        <h5 class="glossary-title">{{ glossaryLabel.driverInformation | translate }}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.driverName | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.name | translate}}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.driverNonLatinName | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.nameNonLatin | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.drivingLicence | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.drivingLicence | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.driverCard | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.driverCard | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.driverAddress | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.addressOfResidence | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.employmentContractStartDate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.employmentContractStartDate | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.applicableLaw | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.applicableLaw | translate }}</span>
                                        </p>

                                        <h5 class="glossary-title">{{ glossaryLabel.identificationDocument | translate }}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.documentType | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.documentType | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.documentNumber | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.number | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.issueDate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.issueDate | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.expiryDate | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.expiryDate | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.issuingCountry | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.issuingCountry | translate }}</span>
                                        </p>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <h5 class="glossary-title">{{ glossaryLabel.companyInformation | translate }}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.companyName | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.name | translate }}</span>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.companyEmail | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.emailAddress | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.licenceNumber | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.licenceNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.nationalRegisterNumber | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.nationalRegisterNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.vatNumber | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.vatNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.countryOfRegistration | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.countryOfRegistration | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.companyAddress | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.address | translate }}</span>
                                        </p>

                                        <h5 class="glossary-title">{{ glossaryLabel.transportManager | translate }}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.transportManagerName | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.name | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.transportManagerEmail | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.emailAddress | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.transportManagerPhone | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.phoneNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.transportManagerAddress | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.addressProfessional | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.certificateOfProfessionalCompetence | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.certificateOfProfessionalCompetence | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.certificateIssuingCountry | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.certificateIssuingCountry | translate }}</span>
                                        </p>

                                        <h5 class="glossary-title">{{ glossaryLabel.contactPerson | translate}}</h5>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.contactPersonName | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.name | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.contactPersonEmail | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.emailAddress | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.contactPersonPhone | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.phoneNumber | translate }}</span>
                                        </p>
                                        <p>
                                            <span class="glossary-section">{{ glossarySection.contactPersonAddress | translate }}</span>
                                            <span class="glossary-label">{{ glossaryLabel.address | translate }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </eui-card-content>
                    </eui-card>
                </div>
            </div>

            <div id="faq" class="section">
                <h3 class="section-title">
                    {{ 'screens.help.faq.section.title' | translate }}
                </h3>
                <div class="section-content mb-3">
                   <p [innerHtml]="'screens.help.faq.section.text' | translate"></p>

                    <div class="faq-list">
                        <div class="faq-list-item" *ngFor="let item of FAQ_ITEMS_ARRAY; let itemIndex = index">
                            <eui-card euiCollapsible [euiCollapsed]="true" [rtpdCardSearchCollapse]="searchText" class="eui-t-card-compact"
                                      *ngIf="('screens.help.faq.section.item.' + itemIndex | translate) != 'screens.help.faq.section.item.' + itemIndex">
                                <eui-card-header>
                                        <eui-card-header-title euiTooltip="{{ 'screens.help.faq.section.item.' + itemIndex | translate }}">
                                            <p class="text-wrap col-12" [innerHtml]="'screens.help.faq.section.item.' + itemIndex | translate"></p>
                                    </eui-card-header-title>
                                </eui-card-header>
                                <eui-card-content>
                                    <p [innerHtml]="'screens.help.faq.section.item.content.' + itemIndex | translate "></p>
                                </eui-card-content>
                            </eui-card>
                        </div>
                    </div>
                </div>
            </div>

            <div id="releases" class="section" *ngIf="showReleasesSection">
                <h3 class="section-title">
                    {{ 'screens.help.releases.section.title' | translate }}
                </h3>
                <div class="section-content">
                    <eui-card euiCollapsible [euiCollapsed]="true" [rtpdCardSearchCollapse]="searchText" (collapse)="showMoreReleases = $event" class="eui-t-card-compact">
                        <eui-card-header hasHeaderClickToggle="true">
                            <eui-card-header-title euiTooltip="{{ 'screens.help.releases.section.text' | translate }}">
                                <p class="text-wrap col-12" [innerHtml]="'screens.help.releases.section.text' | translate "></p>
                            </eui-card-header-title>
                        </eui-card-header>
                        <eui-card-content>
                            <div class="container">
                                <rtpd-releases-component [showMore]="showMoreReleases" (show)="showReleasesSection = $event"></rtpd-releases-component>
                            </div>
                        </eui-card-content>
                    </eui-card>
                </div>
            </div>

        </eui-app-page-wrapper>

        <eui-app-footer id="help-footer" >
            <eui-footer>
                <div class="col-12 text-center">
                    <div class="flex-container justify-content-around">
                        <span>
                            <span *ngIf="appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong> - {{ appReleaseDate }}</span>
                            <span *ngIf="!appReleaseDate">{{ 'screens.common.version.label' | translate }} <strong>{{ appVersion }}</strong></span>
                            <span>
                                |
                                <a *ngIf="privacyStatementLink.url"
                                   target="_blank"
                                   href="{{ privacyStatementLink.url }}">{{ privacyStatementLink.label | translate }}</a>
                            </span>
                        </span>
                    </div>
                </div>
            </eui-footer>
        </eui-app-footer>

    </eui-app>

    <ng-container id="landing-isIE11-footer" *ngIf="isIE11">
        <div>
            <strong>{{ 'screens.common.warning.label' | translate }} </strong> - {{
            'screens.common.obsolete.browser.message' | translate }}
        </div>
    </ng-container>
</div>
