import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getUserState, UserState } from '@eui/core';

import * as coreReducers from '../reducers/core.reducers';

/* Selectors */

const getState = (state: coreReducers.State) => state;

@Injectable({ providedIn: 'root' })
export class CoreSelectors {

  public constructor(
        private store: Store<coreReducers.State>,
  ) {
  }

  public get state$(): Observable<coreReducers.State> {
    return this.store.pipe(select(getState));
  }

  public get userState$(): Observable<UserState> {
    return this.store.pipe(select(getUserState));
  }
}
