import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EuiAutoCompleteItem } from '@eui/components/eui-autocomplete';
import { hasRefDataChanged, reselectFormControlValue } from '@rtpd/shared';
import { BaseComponent } from '../../component/base.component';

@Component({
  selector: 'rtpd-transport-manager-address',
  templateUrl: './transport-manager-address.component.html',
})
export class TransportManagerAddressComponent extends BaseComponent implements OnChanges {
  public postingCountries: EuiAutoCompleteItem[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (hasRefDataChanged(changes.refData)) {
      this.postingCountries = this.refData.postingCountries;
      reselectFormControlValue(this.transportManagerAddressCountry, this.postingCountries);
      this.refDataLoaded = true;
    }
  }

  public get transportManagerAddressStreet(): FormControl {
    return this.form.get('transportManagerAddressStreet') as FormControl;
  }

  public get transportManagerAddressPostCode(): FormControl {
    return this.form.get('transportManagerAddressPostCode') as FormControl;
  }

  public get transportManagerAddressCity(): FormControl {
    return this.form.get('transportManagerAddressCity') as FormControl;
  }

  public get transportManagerAddressCountry(): FormControl {
    return this.form.get('transportManagerAddressCountry') as FormControl;
  }
}
