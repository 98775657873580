import { HttpErrorResponse } from '@angular/common/http';

export class Error {

  public constructor(
        public message: string,
        public error?: HttpErrorResponse | any,
        public severity?: Severity,
  ) {
  }
}

export enum Severity {
    info,
    warning,
    success,
    danger,
}
