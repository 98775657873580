import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../../../component/base.component';

@Component({
    selector: 'rtpd-driver-details',
    templateUrl: './details.component.html',
})
export class DriverDetailsComponent extends BaseComponent {

    public get driverInternalReferenceNumber(): FormControl {
        return this.form.get('driverInternalReferenceNumber') as FormControl;
    }

    public get driverLatinFirstName(): FormControl {
        return this.form.get('driverLatinFirstName') as FormControl;
    }

    public get driverNonLatinFirstName(): FormControl {
        return this.form.get('driverNonLatinFirstName') as FormControl;
    }

    public get driverLatinLastName(): FormControl {
        return this.form.get('driverLatinLastName') as FormControl;
    }

    public get driverNonLatinLastName(): FormControl {
        return this.form.get('driverNonLatinLastName') as FormControl;
    }

    public get driverDateOfBirth(): FormControl {
        return this.form.get('driverDateOfBirth') as FormControl;
    }

    public get driverEmail(): FormControl {
        return this.form.get('driverEmail') as FormControl;
    }

    public get driverLicenseNumber(): FormControl {
        return this.form.get('driverLicenseNumber') as FormControl;
    }

    public get driverCardNumber(): FormControl {
        return this.form.get('driverCardNumber') as FormControl;
    }
}
