<ux-modal id="profile"
    titleLabel="{{ 'screens.user.details.title' | translate }}"
    [isVisible]="true"
    [isShowCloseButton]="false"
    [isFooterCustomAlignment]="true">

    <uxModalBody>
        <div class="row mb-3">
            <div class="col-md-3 offset-md-2">
                <strong>{{ 'screens.user.details.id.label' | translate }}</strong>
            </div>
            <div class="col-md-6">
                {{ userState.userId }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3 offset-md-2">
                <strong>{{ 'screens.user.details.email.label' | translate }}</strong>
            </div>
            <div class="col-md-6">
                {{ userState.email }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3 offset-md-2">
                <strong>{{ 'screens.user.details.fullName.label' | translate }}</strong>
            </div>
            <div class="col-md-6">
                {{ userState.fullName }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-3 offset-md-2">
                <strong>{{ 'screens.user.details.phone.label' | translate }}</strong>
            </div>
            <div class="col-md-6">
                {{ userState?.phone }}
            </div>
        </div>
    </uxModalBody>

    <uxModalFooter>
        <ux-layout-horizontal>
            <uxLayoutHorizontalRight>
                <button euiButton euiPrimary (click)="close()">{{ 'screens.user.details.action.close.label' | translate }}</button>
            </uxLayoutHorizontalRight>
        </ux-layout-horizontal>
    </uxModalFooter>
</ux-modal>
