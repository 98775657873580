import { GlobalConfig } from '@eui/core';

export const GLOBAL: GlobalConfig = {
  appTitle: 'RTPD',
  i18n: {
    i18nService: {
      defaultLanguage: 'en',
      languages: ['bg', 'cs', 'da', 'de', 'en', 'el', 'es', 'et', 'fi', 'fr', 'ga', 'hr', 'hu', 'is', 'it', 'lt', 'lv',
          'mt', 'nl', 'no', 'pl', 'pt', 'ro', 'sk', 'sl', 'sv'],
    },
    i18nLoader: {
      i18nFolders: ['i18n-eui', 'i18n', 'i18n-faq', 'i18n-user-guide', 'i18n-banner'],
    },
  },
  user: {
    defaultUserPreferences: {
      dashboard: {},
      lang: 'en',
    },
  },
};
