import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CoreDispatchers } from '@rtpd/core';
import { Error } from '@rtpd/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'rtpd-files-upload',
    templateUrl: './files-upload.component.html',
    styleUrls: ['./files-upload.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FilesUploadComponent),
        multi: true,
    }],
})
export class FilesUploadComponent implements ControlValueAccessor {
    @Input() public maxFiles = 1;
    @Input() public maxSize = 5567168;
    @Input() public maxName = 80;
    @Input() public accept = '.doc,.docx,.pdf';
    @Input() public browseFilesLabel = 'screens.common.select.file.label';
    @Output() public selected = new EventEmitter<void>();

    private _files: File[];

    public constructor(
        private cd: ChangeDetectorRef,
        private coreDispatchers: CoreDispatchers,
        protected translateService: TranslateService,
    ) {
    }

    public get files(): File[] {
        return this._files;
    }

    public select(event: Event) {
        const inputFiles = Array.from((<HTMLInputElement>event.target).files);
        event.preventDefault();
        if (this.validate(inputFiles)) {
            this.filesChange(inputFiles);
        }
    }

    public selectFiles(input: HTMLInputElement) {
        input.value = null;
        input.click();
    }

    public writeValue(files: File[] | null) {
        this._files = files;
    }

    public onChange: any = () => {
    };

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public onTouched: any = () => {
    };

    public registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    public validate(inputFiles: File[]): boolean {
        if (!inputFiles || inputFiles.length === 0) {
            this.error(this.translateService.instant('screens.common.select.file.validation.no.files.selected.error.message'));
            return false;
        }

        const filesCount = inputFiles.length;
        if (filesCount > this.maxFiles) {
            this.error(this.translateService.instant(
                'screens.common.select.file.validation.num.selected.files.exceed.limit.error.message',
                { maxFiles: this.maxFiles },
            ));
            return false;
        }

        for (const file of inputFiles) {
            const ext = file.name.includes('.') && file.name.substring(file.name.lastIndexOf('.'));
            if (!ext || !this.accept.toUpperCase().includes(ext.toUpperCase())) {
                this.error(this.translateService.instant(
                    'screens.common.select.file.validation.unsupported.doc.type.error.message',
                    { fileName: file.name, accept: this.accept },
                ));
                return false;
            }

            if (file.size > this.maxSize) {
                this.error(this.translateService.instant(
                    'screens.common.select.file.validation.doc.size.limit.exceeded.error.message',
                    { fileName: file.name, size: Math.floor(this.maxSize / 1000000) },
                ));
                return false;
            }

            if (file.name && file.name.length > this.maxName) {
                this.error(this.translateService.instant(
                    'screens.common.select.file.validation.doc.name.length.exceeded.error.message',
                    { fileName: file.name, maxName: this.maxName },
                ));
                return false;
            }
        }
        return true;
    }

    public removeFiles(i) {
        if (this._files && this._files.length === 1) {
            this.filesChange(null);
        } else {
            this.removeFile(i);
        }
    }

    private removeFile(i) {
        const index = this._files.indexOf(i, 0);
        if (index > -1) {
            this._files.splice(index, 1);
        }
    }

    public filesChange(files: File[] | null) {
        this._files = files;
        this.onChange(files);
        this.onTouched();
        this.selected.emit();
    }

    private error(msg: string, error?: any) {
        this.coreDispatchers.handleError(new Error(msg, error));
    }

}
