import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import API from '@aws-amplify/api';

import { SessionStorageService } from '@eui/core';
import { nvl } from '../../core/helpers';
import { HistoryLog } from '@rtpd/shared';
import { TranslateService } from '@ngx-translate/core';
import { HistoryLogType } from '@imi/models';

import { DatePipe } from '@angular/common';
import { xOperatorId } from '@rtpd/core';

@Injectable({ providedIn: 'root' })
export class HistoryLogService {
    private readonly _api = 'HistoryLog';

    private getActiveLanguage() {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }

    public constructor(
        private storageService: SessionStorageService,
        private translateService: TranslateService,
    ) {
    }

    public getAll(): Observable<HistoryLog[]> {
      return from(API.get(this._api, '', {
        headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
      })).pipe(map(this.sortHistoryLogs));
    }

    public declarationHistoryLog(id: string): Observable<HistoryLog[]> {
        return from(API.get(this._api, `/declaration/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
            queryStringParameters: { language: this.getActiveLanguage() },
          })).pipe(map(this.sortHistoryLogs));
    }

    public operatorHistoryLog(id: string): Observable<HistoryLog[]> {
        return from(API.get(this._api, `/operator/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
            queryStringParameters:  { language: this.getActiveLanguage() },
          })).pipe(map(this.sortHistoryLogs));
    }

    public requestHistoryLog(id: string): Observable<HistoryLog[]> {
        return from(API.get(this._api, `/request/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
          })).pipe(map(this.sortHistoryLogs));
    }

    public driverHistoryLog(id: string): Observable<HistoryLog[]> {
        return from(API.get(this._api, `/driver/${id}`, {
            headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
            queryStringParameters:  { language: this.getActiveLanguage() },
          })).pipe(map(this.sortHistoryLogs));
    }

    public apiKeyHistoryLog(id: string): Observable<HistoryLog[]> {
        return from(API.get(this._api, `/apikey/${id}`, {
            headers: { 'x-operator-id': nvl(this.storageService.get('x-operator-id')) },
          })).pipe(map(this.sortHistoryLogs));
    }

    public seenHistoryLogs(): Observable<any> {
        return from(API.post(this._api, '', {
          headers: { [xOperatorId]: nvl(this.storageService.get(xOperatorId)) },
          body: {},
        }));
    }

    public sortHistoryLogs(historyLogs: HistoryLog[]): HistoryLog[] {
        return historyLogs.sort((a, b) => {
            return +b.createdOn - +a.createdOn;
        });
    }

    public showDateTime(createdOn: string): string {
        // Default locale is ok here as we will format the date using a specified format
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(createdOn, 'dd/MM/yyyy HH:mm:ss');
    }

    private translateHistoryLogTitlesGroupOne(historyLog: HistoryLog, translateKeys: string[]): string {
        const [translatePartOne, translatePartTwo, translatePartThree] = translateKeys;
        const partOne = this.translateService.instant(translatePartOne);
        const partTwo = this.translateService.instant(translatePartTwo);
        const partThree = this.translateService.instant(translatePartThree);

        const fields = [HistoryLogType.DECLARATION_EMAIL_SEND_MANUALLY, HistoryLogType.DECLARATION_EMAIL_SEND]
          .includes(historyLog.actionType as HistoryLogType)&& historyLog.field2
              ? `${historyLog.field1} (${historyLog.field2})`
              : historyLog.field1['email'] || historyLog.field1;

        const start = [HistoryLogType.DECLARATION_BULK_SUBMITTED, HistoryLogType.DECLARATION_BULK_UPDATED]
          .includes(historyLog.actionType as HistoryLogType)
            ? `${fields} ${partOne} ${partTwo}`
            : `${partOne} ${fields} ${partTwo}`;

        return `${start} ${historyLog.createdByUsername} ${partThree} ${this.showDateTime(historyLog.createdOn)}`;
    }

    private translateHistoryLogTitlesGroupTwo(historyLog: HistoryLog, translateKeys: string[]): string {
        const [translatePartOne, translatePartTwo] = translateKeys;
        const partOne = this.translateService.instant(translatePartOne);
        const partTwo = this.translateService.instant(translatePartTwo);
        return `${partOne} ${historyLog.field1} ${partTwo} ${this.showDateTime(historyLog.createdOn)}`;
    }

    private translateHistoryLogTitlesGroupThree(historyLog: HistoryLog, translateKeys: string[]): string {
        const [translatePartOne, translatePartTwo] = translateKeys;
        const partOne = this.translateService.instant(translatePartOne);
        const partTwo = this.translateService.instant(translatePartTwo);

        return `${partOne} ${historyLog.createdByUsername} ${partTwo} ${this.showDateTime(historyLog.createdOn)}`;
    }

    private getRequestDocumentTypeProvidedTranslation(documentType: string): string {
        switch (documentType) {
            case 'CMR':
                return this.translateService.instant('screens.history.log.action.type.request.document.type.provided.cmr');
            case 'TACHOGRAPH_RECORD':
                return this.translateService.instant('screens.history.log.action.type.request.document.type.provided.tachograph.record');
            case 'PAYSLIP':
                return this.translateService.instant('screens.history.log.action.type.request.document.type.provided.payslip');
            case 'LABOUR_CONTRACT':
                return this.translateService.instant('screens.history.log.action.type.request.document.type.provided.labour.contract');
            case 'TIMESHEET':
                 // eslint-disable-next-line max-len
                return this.translateService.instant('screens.common.document.type.timesheet');
            case 'PROOF_OF_PAYMENT':
                // eslint-disable-next-line max-len
                return this.translateService.instant('screens.common.document.type.proof_of_payment');
            default:
                return '';
        }
    }

    public constructHistoryLogTitle(historyLog: HistoryLog): string {
        if(historyLog.actionType === HistoryLogType.DRIVER_CREATED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.driver',
                'screens.history.log.action.type.driver.added',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DRIVER_UPDATE) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.driver',
                'screens.history.log.action.type.driver.updated',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DRIVER_DELETED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.driver',
                'screens.history.log.action.type.driver.removed',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_DRAFT_CREATED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.declaration.draft.created',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_SUBMITTED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.declaration.submitted',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_EMAIL_SEND) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
              ['screens.history.log.action.type.declaration.email.send',
                  'screens.history.log.text.by',
                  'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_EMAIL_SEND_MANUALLY) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
              ['screens.history.log.action.type.declaration.email.send.manually',
                  'screens.history.log.text.by',
                  'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_BULK_SUBMITTED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
              ['screens.history.log.action.type.declaration.bulk.submitted',
                  'screens.history.log.text.by',
                  'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_BULK_UPDATED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
              ['screens.history.log.action.type.declaration.bulk.edited',
                  'screens.history.log.text.by',
                  'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_AMENDED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.declaration.amended',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_WITHDRAWN) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.declaration.withdrawn',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DECLARATION_DELETED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.declaration.deleted',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_DOCUMENT_ADDED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.request.document.added',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_DOCUMENT_REMOVED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.request.document.removed',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_MESSAGE_SEND) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.request.message.send',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_ALL_DOCUMENT_TYPES_PROVIDED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.request.all.document.types.provided',
                'screens.history.log.text.by',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.USER_INVITED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.user.invited.joined',
                'screens.history.log.action.type.user.invited.second.part',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.USER_JOINED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.user.invited.joined',
                'screens.history.log.action.type.user.joined.second.part',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.USER_DELETED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
              ['screens.history.log.action.type.user.invited.joined',
                  'screens.history.log.action.type.user.deleted.second.part',
                  'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.OPERATOR_CREATED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.operator.created.updated',
                'screens.history.log.action.type.operator.created.second.part',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.OPERATOR_UPDATED) {
            return this.translateHistoryLogTitlesGroupOne(historyLog,
                ['screens.history.log.action.type.operator.created.updated',
                'screens.history.log.action.type.operator.updated.second.part',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.APIKEY_CREATED) {
            return this.translateHistoryLogTitlesGroupThree(historyLog,
                ['screens.history.log.action.type.apikey.created',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.APIKEY_RECREATED) {
            return this.translateHistoryLogTitlesGroupThree(historyLog,
                ['screens.history.log.action.type.apikey.recreated',
                'screens.history.log.text.on']);
        }

        if(historyLog.actionType === HistoryLogType.DRIVER_IMPORT) {
            const translatePartOne = this.translateService.instant('screens.history.log.action.type.driver.imported');
            const translatePartTwo = this.translateService.instant('screens.history.log.text.on');
            return `${historyLog.field1} ${translatePartOne} ${historyLog.createdByUsername} ${translatePartTwo}
            ${this.showDateTime(historyLog.createdOn)}`;
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_HOME_AUTHORITY_INVOLVED) {
            return this.translateHistoryLogTitlesGroupTwo(historyLog, [
                'screens.history.log.action.type.request.home.authority.involved',
                'screens.history.log.text.on',
            ]);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_HOME_AUTHORITY_EXCHANGE) {
            return this.translateHistoryLogTitlesGroupTwo(historyLog, [
                'screens.history.log.action.type.request.home.authority.exchange',
                'screens.history.log.text.on',
            ]);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_CLOSED) {
            return this.translateHistoryLogTitlesGroupTwo(historyLog, [
                'screens.history.log.action.type.request.closed',
                'screens.history.log.action.type.request.closed.second.part',
            ]);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_DELETED) {
            return this.translateHistoryLogTitlesGroupTwo(historyLog, [
                'screens.history.log.action.type.request.deleted',
                'screens.history.log.action.type.request.deleted.second.part',
            ]);
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_CREATED) {
            const translatePartOne = this.translateService.instant('screens.history.log.action.type.request.created');
            const translatePartTwo = this.translateService.instant('screens.history.log.text.by');
            const translatePartThree = this.translateService.instant('screens.history.log.text.on');
            return `${translatePartOne} ${historyLog.field1} ${translatePartTwo} ${historyLog.field2}, ${historyLog.field3}
            ${translatePartThree} ${this.showDateTime(historyLog.createdOn)}`;
        }

        if(historyLog.actionType === HistoryLogType.REQUEST_DOCUMENT_TYPE_PROVIDED) {
            const translatePartOne = this.translateService.instant('screens.history.log.action.type.request.document.type.provided');
            const translateDocumetType = this.getRequestDocumentTypeProvidedTranslation(historyLog.field2);
            // eslint-disable-next-line max-len
            const translatePartTwo = this.translateService.instant('screens.history.log.action.type.request.document.type.provided.second.part');
            const translatePartThree = this.translateService.instant('screens.history.log.text.by');
            const translatePartFour = this.translateService.instant('screens.history.log.text.on');
            // eslint-disable-next-line max-len
            return `${translatePartOne} ${translateDocumetType} ${translatePartTwo} ${historyLog.field1} ${translatePartThree} ${historyLog.createdByUsername}
            ${translatePartFour} ${this.showDateTime(historyLog.createdOn)}`;
        }

    }
}
