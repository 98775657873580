import { Observable } from 'rxjs';
import { filter, map, pairwise, pluck, startWith } from 'rxjs/operators';

export function changedMultiSortMeta() {
    return function(source: Observable<any>): Observable<any> {
        return source.pipe(
            pluck('multisortmeta'),
            startWith(null as { [field: string]: number }),
            pairwise(),
            filter(onlyChangedMultisortMeta),
            map(multisortmeta => multisortmeta[1]),
        );
    };
}

export function onlyChangedMultisortMeta(multisortmeta) {
    const prev = multisortmeta[0];
    const current = multisortmeta[1];
    return (!prev && current) || (!current && prev)
        || ((current[current.length - 1].field !== undefined) && prev[prev.length - 1].field !== current[current.length - 1].field)
        || ((current[current.length - 1].order !== undefined) && prev[prev.length - 1].order !== current[current.length - 1].order);
}
