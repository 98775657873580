import { User } from '@rtpd/shared';

export class UsersSearchResult {

    public items:  Array<User>;

    public constructor(values: Object = {}) {
      Object.assign(this, values);
    }
}
