import { Action } from '@ngrx/store';
import { Error, HistoryLog } from '@rtpd/shared';
import { Account } from '../models/account';
import { Declaration, SearchFilters } from '../../declarations/models';

export const GET_ACCOUNT = '[RTPD Account] Get';
export const GET_ACCOUNT_SUCCESS = '[RTPD Account] Get Success';
export const GET_ACCOUNT_FAIL = '[RTPD Account] Get Fail';

export const CREATE_ACCOUNT = '[RTPD Account] Create';
export const CREATE_ACCOUNT_SUCCESS = '[RTPD Account] Create Success';
export const CREATE_ACCOUNT_FAIL = '[RTPD Account] Create Fail';

export const CHANGE_ACCOUNT = '[RTPD Account] Change';
export const EDIT_ACCOUNT = '[RTPD Account] Edit';
export const UPDATE_ACCOUNT = '[RTPD Account] Update';
export const UPDATE_ACCOUNT_SUCCESS = '[RTPD Account] Update Success';
export const UPDATE_ACCOUNT_FAIL = '[RTPD Account] Update Fail';
export const CANCEL_ACCOUNT = '[RTPD Account] Cancel';
export const CANCEL_UPDATE_ACCOUNT = '[RTPD Account] Update Cancel';

export const DELETE_ACCOUNT = '[RTPD Account] Delete';
export const DELETE_ACCOUNT_SUCCESS = '[RTPD Account] Delete Success';
export const DELETE_ACCOUNT_FAIL = '[RTPD Account] Delete Fail';
export const SEARCH_DECLARATIONS = '[RTPD Account] Search Declarations';
export const SEARCH_DECLARATIONS_SUCCESS = '[RTPD Account] Search Declarations Success';
export const SEARCH_DECLARATIONS_FAIL = '[RTPD Account] Search Declarations Fail';

export const REF_DATA_LOADED = '[RTPD Account] Ref Data Loaded';

export const GET_HISTORY_LOG_ACCOUNT = '[RTPD Account] Get Histoy Log';
export const GET_HISTORY_LOG_ACCOUNT_SUCCESS = '[RTPD Account] Get Histoy Log success';
export const GET_HISTORY_LOG_ACCOUNT_FAIL = '[RTPD Account] Get Histoy Log fail';

export class GetAccountAction implements Action {
    public readonly type: string = GET_ACCOUNT;

    public constructor(public payload: string) {
    }
}

export class GetAccountSuccessAction implements Action {
    public readonly type: string = GET_ACCOUNT_SUCCESS;

    public constructor(public payload: Account) {
    }
}

export class GetAccountFailAction implements Action {
    public readonly type: string = GET_ACCOUNT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class CreateAccountAction implements Action {
    public readonly type: string = CREATE_ACCOUNT;
}

export class CreateAccountSuccessAction implements Action {
    public readonly type: string = CREATE_ACCOUNT_SUCCESS;
}

export class CreateAccountFailAction implements Action {
    public readonly type: string = CREATE_ACCOUNT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class DeleteAccountAction implements Action {
    public readonly type: string = DELETE_ACCOUNT;
    public constructor(public payload: string) {}
}

export class DeleteAccountSuccessAction implements Action {
    public readonly type: string = DELETE_ACCOUNT_SUCCESS;
}

export class DeleteAccountFailAction implements Action {
    public readonly type: string = DELETE_ACCOUNT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class SearchDeclarationsAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS;

    public constructor(public payload: SearchFilters = undefined) {
    }
}

export class SearchDeclarationsSuccessAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_SUCCESS;
    public constructor(public payload: Array<Declaration>) { }
}

export class SearchDeclarationsFailAction implements Action {
    public readonly type: string = SEARCH_DECLARATIONS_FAIL;
    public constructor(public payload: Error) { }
}

export class ChangeAccountAction implements Action {
    public readonly type: string = CHANGE_ACCOUNT;

    public constructor(public payload: Account) {
    }
}

export class CancelAccountAction implements Action {
    public readonly type: string = CANCEL_ACCOUNT;
}

export class CancelUpdateAccountAction implements Action {
    public readonly type: string = CANCEL_UPDATE_ACCOUNT;
}

export class EditAccountAction implements Action {
    public readonly type: string = EDIT_ACCOUNT;

    public constructor(public payload: string) {
    }
}

export class UpdateAccountAction implements Action {
    public readonly type: string = UPDATE_ACCOUNT;
}

export class UpdateAccountSuccessAction implements Action {
    public readonly type: string = UPDATE_ACCOUNT_SUCCESS;
}

export class UpdateAccountFailAction implements Action {
    public readonly type: string = UPDATE_ACCOUNT_FAIL;

    public constructor(public payload: Error) {
    }
}

export class GetHistoryLogAccountAction implements Action {
    public readonly type: string = GET_HISTORY_LOG_ACCOUNT;
    public constructor(public payload: string) {}
}

export class GetHistoryLogAccountSuccessAction implements Action {
    public readonly type:string = GET_HISTORY_LOG_ACCOUNT_SUCCESS;
    public constructor(public payload: HistoryLog[]) {}
}

export class GetHistoryLogAccountFailAction implements Action {
    public readonly type: string = GET_HISTORY_LOG_ACCOUNT_FAIL;
    public constructor(public payload: Error) {}
}

export class RefDataLoadedAction implements Action {
    public readonly type: string = REF_DATA_LOADED;
}

export type Actions =
    GetAccountAction |
    GetAccountSuccessAction |
    GetAccountFailAction |
    CreateAccountAction |
    CreateAccountSuccessAction |
    CreateAccountFailAction |
    ChangeAccountAction |
    EditAccountAction |
    UpdateAccountAction |
    UpdateAccountSuccessAction |
    UpdateAccountFailAction |
    CancelUpdateAccountAction |
    CancelAccountAction |
    RefDataLoadedAction |
    GetHistoryLogAccountAction |
    GetHistoryLogAccountSuccessAction |
    GetHistoryLogAccountFailAction |
    SearchDeclarationsAction |
    SearchDeclarationsSuccessAction |
    SearchDeclarationsFailAction;

