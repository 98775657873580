import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getUserState, UpdateUserDetailsAction, UserDetails } from '@eui/core';

import { Error } from '@rtpd/shared';
import * as coreActions from '../actions/core.actions';
import * as coreReducers from '../reducers/core.reducers';

@Injectable({ providedIn: 'root' })
export class CoreDispatchers {

  public constructor(
        private store: Store<coreReducers.State>,
  ) {
  }

  public updateUserDetails(user: UserDetails) {
    this.store.dispatch(new UpdateUserDetailsAction(user));
  }

  public toggleUserProfile() {
    this.store.pipe(
      select(getUserState),
      take(1),
    ).subscribe(state => {
      this.updateUserDetails({
        ...state,
        isUserProfileOpen: !state.isUserProfileOpen,
      });
    });
  }

  public handleError(error: Error) {
    this.store.dispatch(new coreActions.HandleErrorAction(error));
  }

  public navigate(path: { route: string[]; state?: any }) {
    this.store.dispatch(new coreActions.NavigateAction(path));
  }
}
