import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../../core/helpers/null-value.util';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'countryFlag' })
export class CountryFlagPipe implements PipeTransform {

  public transform(countryCode: string): string {
    if (isEmpty(countryCode)) {
      return '';
    }
    return 'eui-flag-icon-' + countryCode.toLowerCase();
  }
}
