import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from '../../core/helpers/null-value.util';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {
  public constructor(
    private translateService: TranslateService,
  ) { }

  public transform(countryCode: string): string {
    if (isEmpty(countryCode)) {
      return '';
    }
    return this.translateService.instant('screens.common.countries.' + countryCode.toLowerCase());
  }
}
