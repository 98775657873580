import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserState } from '@eui/core';
import { CoreDispatchers } from '../../services';
import { RoleLabel } from '@rtpd/shared';

@Component({
    selector: 'rtpd-user-profile',
    templateUrl: './user-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
    @Input() public userState: UserState;

    public RoleLabelType = RoleLabel;

    public constructor(
        private coreDispatchers: CoreDispatchers,
    ) {
    }

    public close() {
        this.coreDispatchers.toggleUserProfile();
    }
}
