import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { HistoryLog, HistoryLogService } from '@rtpd/shared';
import moment from 'moment';

@Component({
  selector: 'rtpd-history-log',
  templateUrl: './history-log.component.html',
  styleUrls: ['./history-log.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryLogComponent {
    @Input() public historyLogs: HistoryLog[];

    public constructor(protected historyLogService: HistoryLogService) { }

    public historyLogTitle(historyLog: HistoryLog): string {
        return this.historyLogService.constructHistoryLogTitle(historyLog);
    }

    public splitField(fieldName: string): string {
        let splitNames = fieldName.split(/(?=[A-Z])/);
        const name = `${splitNames[0].charAt(0).toUpperCase()}${splitNames[0].slice(1)}`;
        if (splitNames.length === 1) {
            return name;
        }
        splitNames.shift();
        splitNames = [name, ...splitNames];
        return splitNames.join(' ');
    }

    public isDate(value: any): boolean {
        return value != undefined && moment(value).isValid();
    }

}
