import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppShellService {

    private showOperatorSubject$ = new ReplaySubject<boolean>();

    public onShowOperatorUpdate(): Observable<boolean> {
      return this.showOperatorSubject$.asObservable();
    }

    public showOperator() {
      this.showOperatorSubject$.next(true);
    }

    public hideOperator() {
      this.showOperatorSubject$.next(false);
    }

}
