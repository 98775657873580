<div *ngIf="refDataLoaded">
    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.transport.email.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="transportManagerEmail" [isReadOnly]="isReadOnly" [maxlength]="254" [isShowMaxlengthCounter]="false">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerEmail.touched">
            <ux-control-feedback *ngIf="transportManagerEmail.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="transportManagerEmail.hasError('email')" typeClass="danger">
                {{ 'screens.common.message.field.valid.email' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.transport.firstName.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="transportManagerFirstName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerFirstName.touched">
            <ux-control-feedback *ngIf="transportManagerFirstName.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.transport.lastName.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="transportManagerLastName" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerLastName.touched">
            <ux-control-feedback *ngIf="transportManagerLastName.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.transport.phone.label' | translate }}"
                       [isRequired]="!isReadOnly">
            <ux-form-control  [formControl]="transportManagerPhone" [isReadOnly]="isReadOnly" [maxlength]="20" [isShowMaxlengthCounter]="false">
            </ux-form-control>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerPhone.touched">
            <ux-control-feedback *ngIf="transportManagerPhone.hasError('required')" typeClass="danger">
                {{ 'screens.common.message.field.required' | translate }}
            </ux-control-feedback>
            <ux-control-feedback *ngIf="transportManagerPhone.hasError('phone')" typeClass="danger">
                {{ 'screens.common.message.field.valid.phone' | translate }}
            </ux-control-feedback>
        </div>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.create.transport.license.number.label' | translate }}">
            <ux-form-control  [formControl]="transportManagerLicenseNumber" [isReadOnly]="isReadOnly" [maxlength]="1000" [isShowMaxlengthCounter]="false"
                maxLengthValidationErrorMessage="{{ 'screens.common.message.field.valid.characters.1000' | translate }}">
            </ux-form-control>
        </ux-form-group>
    </div>

    <div class="rtpd-form-formgroup-displayed col-md-9">
        <ux-form-group label="{{ 'screens.account.management.transport.certificate.issuing.country.label' | translate }}">
            <eui-autocomplete isItemsSorted [readonly]="isReadOnly" [formControl]="transportManagerLicenseCountry"
                              [autocompleteData]="postingCountries" [itemsSortOrder]="'ASC'"
                              placeholder="{{ isReadOnly ? '' : 'screens.common.placeholder.select' | translate }}">
            </eui-autocomplete>
        </ux-form-group>
        <div class="rtpd-form-errors-displayed col-md-8 offset-md-4" *ngIf="showErrors || transportManagerLicenseCountry.hasError('validCountry')">
            <ux-control-feedback *ngIf="transportManagerLicenseCountry.hasError('validCountry')" typeClass="danger">
                {{ 'screens.common.message.field.valid.country' | translate }}
            </ux-control-feedback>
        </div>
    </div>
</div>
