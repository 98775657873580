export enum Status {
    CLOSED = 'CLOSED',
    DRAFT = 'DRAFT',
    EXPIRED = 'EXPIRED',
    STARTED = 'STARTED',
    SUBMITTED = 'SUBMITTED',
    WITHDRAWN = 'WITHDRAWN',
}

export const StatusLabel = new Map<string, string>([
  [Status.DRAFT, 'screens.declarations.status.draft.label'],
  [Status.SUBMITTED, 'screens.declarations.status.submitted.label'],
  [Status.CLOSED, 'screens.declarations.status.closed.label'],
  [Status.EXPIRED, 'screens.declarations.status.expired.label'],
  [Status.STARTED, 'screens.declarations.status.started.label'],
  [Status.WITHDRAWN, 'screens.declarations.status.withdrawn.label'],
]);
