import { Injectable } from '@angular/core';
import { HttpResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    private cache = new Map<string, any>();
    private cacheUrls = ['assets/refdata'];

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cacheUrls.some(url => request.url.startsWith(url))) {
            const cached = this.cache.get(request.urlWithParams);
            if (cached) {
                return of(cached);
            }
            return next.handle(request).pipe(
                tap(response => {
                    if (response instanceof HttpResponse) {
                        this.cache.set(request.urlWithParams, response);
                    }
                }),
            );
        }
        return next.handle(request);
    }
}
