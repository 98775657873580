export enum Permission {
    ViewHome,
    ViewDeclarations,
    ManageDeclarations,
    ViewDrivers,
    ManageDrivers,
    ViewRequests,
    ManageRequests,
    ViewUsers,
    ManageUsers,
    ViewAccount,
    ViewApiKey,
    ManageAccount,
    ViewSystemSettings,
    ViewHelp,
}
