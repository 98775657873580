import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[autocompleteOff]',
})

export class AutocompleteOffDirective {

    public constructor(private el: ElementRef) {
    }

    public ngAfterViewInit(): void {
        this.autocompleteOff();
    }

    public ngAfterViewChecked(): void {
        this.autocompleteOff();
    }

    private autocompleteOff() {
        const inputNodes = this.el.nativeElement.getElementsByTagName('input');
        for (const input of inputNodes) {
            input.setAttribute('autocomplete', 'nope');
            input.setAttribute('autocorrect', 'off');
            input.setAttribute('autocapitalize', 'none');
            input.setAttribute('spellcheck', 'false');
        }
    }
}
