import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { RefDataService } from '../../core/services/refdata.service';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'refData' })
export class RefDataPipe implements PipeTransform {
  public constructor(
    private refDataService: RefDataService,
  ) { }

  public transform(key: number | string, type: string, property?: string, param?: number | string): Observable<any> {
    return (this.refDataService[`formatted${type}$`] || this.refDataService[`getFormatted${type}`](param)).pipe(
      map((refs: any[]) => {
        const ref = key != null && refs && refs.find(
          r => r.id.toString() === key.toString() ||
              (r.code && r.code.toString().toUpperCase() === key.toString().toUpperCase()),
        );
        if (ref != null) {
          return (property && ref[property]) || ref;
        } else {
          return undefined;
        }
      },
      take(1),
      ));
  }
}
